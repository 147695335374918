import React from 'react';
import { Card, Button } from 'antd';
import '../styles/CampaignCard.scss'; // Make sure this path is correct
import Meta from 'antd/es/card/Meta';

type CampaignCardProps = {
  name: string;
  amountRaised: string;
  endDate: string;
  status: string;
  onDetailsClick: () => void;
};

const CampaignCard: React.FC<CampaignCardProps> = ({
  name,
  amountRaised,
  endDate,
  status,
  onDetailsClick
}) => {
  return (
    <Card className={`campaign-card ${status.toLowerCase()}`}>
      <Meta 
        title={name} 
        description="Campaign Name"
      />
      <Meta 
        title={amountRaised} 
        description="Raised by Me" 
      />
      <Meta 
        title={endDate} 
        description="End Date" 
      />
     <div className={`details-button ${status.toLowerCase()}`}>
     <span className="details-text">{status} Campaign</span>
        <Button type="primary" onClick={onDetailsClick}>Details</Button>
      </div>
    </Card>
  );
};

export default CampaignCard;
