import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, ConfigProvider, Flex, Input, Spin, Switch, Typography, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import CurrencyInput from 'react-currency-input-field';
import { useLazyGetUserQuery, useUpdateUserMutation } from "../services/admin/adminAuth";
import { useForm, Controller } from "react-hook-form";
import "../styles/UpdateUser.scss";
import ChangePassword from "./ChangePassword";
import ImageUploader from './ImageUploader';
import TextArea from "antd/es/input/TextArea";
const { Text } = Typography;

interface UpdateUserFormValues {
  is_active: boolean;
  is_superuser: boolean;
  scope: string;
  in_leaderboard: boolean;
  id: number;
  full_name: string;
  email: string;
  primary_image_url: string;
  raise_goal: number;
  blurb: string;
}

const UpdateUser: React.FC = () => {
  const dispatch = useDispatch();
  const { control, handleSubmit, setValue, formState: { errors } } = useForm<UpdateUserFormValues>();
  const [getUser, { data: userData, isLoading: userLoading, isError: userError }] = useLazyGetUserQuery();
  const [updateUser, { isLoading: updateLoading, isError: updateError, error }] = useUpdateUserMutation();
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    if (userData) {
      setValue("full_name", userData.full_name);
      setValue("email", userData.email);
      setValue("primary_image_url", userData.primary_image_url);
      setValue("raise_goal", userData.raise_goal);
      setValue("blurb", userData.blurb);
      setValue("in_leaderboard", userData.in_leaderboard);
      
    }
  }, [userData, setValue]);

  const onSubmit = async (data: UpdateUserFormValues) => {
    try {
      await updateUser(data).unwrap();
      message.success('User updated successfully!');
    } catch (err) {
      console.error('Failed to update user:', err);
      message.error('Failed to update user. Please try again.');
    }
  };

  if (userLoading) return <Spin />;
  if (userError) return <Text type="danger">Failed to fetch user data.</Text>;
  const handleProfileImageUpload = (imageUrl: string) => {
    setValue('primary_image_url', imageUrl, { shouldValidate: true });
  };

  return (
    <> 
    

    

    <div className="update-user-container">
      <div>
      <p style={{
        color:"#343C6A",fontSize:"18px",fontWeight:"Bolder",paddingLeft:"30px",
      }}>Profile</p>
        
        </div>
      <form onSubmit={handleSubmit(onSubmit)} className="update-user-form">
        <div className="profile-container">
          <div className="profile-image-wrapper">
          <Controller
                name="primary_image_url"
                control={control}
               
                render={({ field }) => (
                  <>
                  {field.value && (
                      <img src={field.value} alt="Profile" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }} />
                    )}
                    <div
                      className="edit-icon-overlay"
                      onClick={() => setIsEditing(true)}
                      style={{ cursor: 'pointer', position: 'absolute', bottom: '10px', right: '10px', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '50%', padding: '5px' }}
                    >
                        <EditOutlined style={{ color: '#fff' }} />
                        </div>
                        {isEditing && (
                      <ImageUploader
                        onUpload={handleProfileImageUpload}
                        width={512}
                        height={512}
                      />
                    )}
                    </>
                    )}
                    />
                    {errors.primary_image_url && <Text type="danger">{errors.primary_image_url.message}</Text>}
          </div>
        
           
          <div className="form-content">
            <div className="form-item">
              <label>Full Name</label>
              <Controller
                name="full_name"
                control={control}
                rules={{ required: "Full Name is required" }}
                render={({ field }) => (
                  <Input {...field} placeholder="Full Name" />
                )}
              />
              {errors.full_name && <Text type="danger">{errors.full_name.message}</Text>}
            </div>

            <div className="form-item">
              <label>Email</label>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Invalid email address"
                  }
                }}
                render={({ field }) => (
                  <Input {...field} placeholder="Email" />
                )}
              />
              {errors.email && <Text type="danger">{errors.email.message}</Text>}
            </div>

            <div className="form-item">
              <label>Raise Goal</label>
              <Controller
                name="raise_goal"
                control={control}
                rules={{ required: "Raise Goal is required" }}
                render={({ field }) => (
                  <CurrencyInput
                    className='ant-input css-dev-only-do-not-override-zg0ahe ant-input-outlined'
                    placeholder="Please enter Goal"
                    value={field.value / 100}
                    decimalsLimit={2}
                    onValueChange={(value) => {
                      const centsValue = value ? parseFloat(value) * 100 : 0;
                      field.onChange(centsValue);
                    }}
                    prefix="$"
                  />
                )}
              />
              {errors.raise_goal && <Text type="danger">{errors.raise_goal.message}</Text>}
            </div>
            

            <div className="form-item full-width">
              <label>My Fundraising Message</label>
              <Controller
                name="blurb"
                control={control}
                render={({ field }) => (
                  <TextArea
                  {...field}
      showCount
      maxLength={800}
     
      placeholder="Blurb"
      style={{ height: 120, resize: 'none' }}
    />
                )}
              />
            </div>

            

            {updateError && (
              <div className="form-item full-width">
                <Text type="danger">{error ? error.toString() : 'Failed to update user. Please try again.'}</Text>
              </div>
            )}

            <div className="submit-button-container">
              <Button type="primary" htmlType="submit" loading={updateLoading} className="red-button">
                Save
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <ChangePassword/>
    </>
    
 
  );

};

export default UpdateUser;
