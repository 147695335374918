// File: src/components/ResetPassword.tsx
import React from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Form, Input, Typography, theme, Spin } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useResetPasswordMutation } from '../services/admin/adminAuth';
import { validatePasswordRules } from "../utils";

const { useToken } = theme;
const { Title, Text } = Typography;

const ResetPassword: React.FC = () => {
  const { token } = useToken();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resetToken = searchParams.get('token') || '';
  const [resetPassword, { error, isLoading }] = useResetPasswordMutation();

  const handleResetPassword = async (values: { password: string; confirmPassword: string }) => {
    const { password, confirmPassword } = values;
    if (password !== confirmPassword) {
      return; // Handle error: passwords do not match
    }
    try {
      await resetPassword({ token: resetToken, password }).unwrap();
      navigate("/signin");
    } catch (err) {
      console.error('Failed to reset password:', err);
    }
  };

  const styles: { [key: string]: React.CSSProperties } = {
    container: {
      margin: "0 auto",
      padding: token.paddingXL,
      width: "380px"
    },
    header: {
      marginBottom: token.marginXL
    },
    text: {
      color: token.colorTextSecondary
    },
    title: {
      fontSize: token.fontSizeHeading2
    }
  };

  return (
    <section style={{ display: "flex", alignItems: "center", height: "100vh" }}>
      <div style={styles.container}>
        <div style={styles.header}>
          <Title style={styles.title}>Reset Password</Title>
          <Text style={styles.text}>
            Please enter your new password below.
          </Text>
        </div>
        <Form
          name="reset_password"
          onFinish={handleResetPassword}
          layout="vertical"
        >
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                validator: validatePasswordRules,
            message: "Minimum length of at least 8 characters and contain both numeric and alphabetic",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="New Password"
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: "Please confirm your new Password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords do not match!'));
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="Confirm New Password"
            />
          </Form.Item>
          {error && (
            <Form.Item>
              <Text type="danger">
                'Failed to reset password. Please try again.'
              </Text>
            </Form.Item>
          )}
          <Form.Item>
            <Button block type="primary" htmlType="submit" loading={isLoading}>
              Reset Password
            </Button>
          </Form.Item>
        </Form>
        {isLoading && (
          <div style={{ textAlign: 'center', marginTop: 20 }}>
            <Spin />
          </div>
        )}
      </div>
    </section>
  );
};

export default ResetPassword;

