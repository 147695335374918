import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import AddToCartButton from './AddToCartButton';
import '../styles/ProductList.scss';
import { useGetCampaignForUserQuery } from '../services/campaign';
import { formatPrice } from '../utils';
import Modal from './Modal';
import MessageCarousel from './MessageCarousel';

const ProductList: React.FC<{ selectedOption: string }> = ({ selectedOption }) => {
  const { campaign_id } = useParams<{ campaign_id: string }>();
  const { data, error, isLoading, isError } = useGetCampaignForUserQuery(campaign_id || '0', {
    skip: !campaign_id,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);

  const handleOpenModal = (product: any) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  const truncateText = (text: string, wordLimit: number) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  const campaignData = {
    ...data,
    products: data?.products || []
  };

  const filteredProducts = campaignData.products.filter((product) => {
    if (selectedOption === 'home') {
      return product.fulfilment_type === 'STH';
    } else if (selectedOption === 'school') {
      return product.fulfilment_type === 'STS';
    } else if (selectedOption === 'donate') {
      return product.primary_image_url === undefined; // Assuming donation cards don't have primary_image_url
    }
    return true;
  });
 if(data?.is_closed) return <></> 
  if (isError) return <div>Error loading products</div>;
  if (isLoading) {
    return <div></div>;
  }

  return (
    <div className="product-list-container">
      <div className="product-container">
      {filteredProducts.map((product) => (
        <div className="product-card" key={product.id}>
          <div className="product-image">
            <img src={product.primary_image_url} alt={product.title} />
          </div>
          <div className="product-details">
            <div className="product-name">{product.title}</div>
            <div
              className="product-description"
              dangerouslySetInnerHTML={{ __html: truncateText(product.blurb, 20) }}
            ></div>
            <div className="product-price">${formatPrice(product.cost)}</div>
            <div className="type">{product.fulfilment_type === "STH" ? 'Ship to Home' : 'Ship to School'}</div>
            <div className="button-container">
              <button onClick={() => handleOpenModal(product)} className="button view-product">
                View Product
              </button>
              <AddToCartButton product={product} />
            </div>
          </div>
        </div>
      ))}
      </div>
     
      {selectedProduct && (
        <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
          <div className="product-details-modal">
            <img src={selectedProduct.primary_image_url} alt={selectedProduct.title} className="product-image-modal" />
            <div className="product-details-content">
              <h1>{selectedProduct.title}</h1>
              
              <div className="product-price-modal">${formatPrice(selectedProduct.cost)}</div>
              <div className="button-container-modal">
                <AddToCartButton product={selectedProduct} show={false} />
              </div>
              <p className="product-description" dangerouslySetInnerHTML={{ __html: selectedProduct.blurb }}></p>
             
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ProductList;
