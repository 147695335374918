import React, { useEffect, useState } from 'react';
import { Table, Spin, message, Button, Pagination, Row, Col, Card } from 'antd';
import {
  useGetCampaignsQuery,
  useDownloadReportMutation,
  useInviteUsersMutation,
  useGetInviteStudentsQuery,
} from '../services/admin/inventoryApi';
import usePagination from '../hooks/usePagination';
import { Campaign } from '../types.ts';
import {
  CopyOutlined,
  DownloadOutlined,
  StepBackwardFilled,
} from '@ant-design/icons';
import CSVUploader from './CSVUploader';
import { useLazyGetUserQuery } from '../services/admin/adminAuth';
import ContactsModal from './ContactsModal';
import LeaderBoardModal from './LeaderBoardModal';
// @ts-ignore
import { CSVLink, CSVDownload } from 'react-csv';

import { Typography } from 'antd';
import CampaignCard from './CampaignCard';
// @ts-ignore
import SvgIcon from '../assets/svg/Man';
import { convertUTCDateToLocalDate, formatWithCommas } from '../utils';
import '../styles/StudentCampaign.scss';
import Title from 'antd/es/typography/Title';
import Meta from 'antd/es/card/Meta';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCashRegister,
  faDollarSign,
  faHandHoldingDollar,
  faSackDollar,
} from '@fortawesome/free-solid-svg-icons';
import { FaArrowAltCircleLeft, FaStepBackward } from 'react-icons/fa';
import OrdersTable from './OrdersTable';
import Loader from './Loader';
import { useNavigate } from 'react-router-dom';

interface ParsedData {
  email_address: string;
  name: string;
}
const { Paragraph } = Typography;

const StudentCampaigns: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  const [campaignId, setCampaignId] = useState(null);
  const { data: studentData, isLoading: isLoadingStudents } =
    useGetInviteStudentsQuery({
      campaignId: campaignId ? campaignId : '',
      skip: 0,
      limit: 10,
    });
  const { currentPage, pageSize, onPageChange } = usePagination();
  const [getUser, { data: user }] = useLazyGetUserQuery();
  const { data, error, isLoading } = useGetCampaignsQuery({
    skip: (currentPage - 1) * pageSize,
    limit: pageSize,
  });
  const [downloadReport] = useDownloadReportMutation();
  const [contactsModalVisible, setContactsModalVisible] = useState(false);
  const [leaderBoardModalVisible, setLeaderBoardModalVisible] = useState(false);
  const navigate = useNavigate();
  const [selectedContactsCampaignId, setSelectedContactsCampaignId] = useState<
    string | null
  >(null);

  useEffect(() => {
    getUser();
  }, [getUser]);
  const [inviteUsers, { isLoading: isUploadingCSV }] = useInviteUsersMutation();
  useEffect(() => {
    if (error) {
      message.error('Failed to load campaigns');
    }
  }, [error]);

  const handleDataParsed = async (data: ParsedData[], id: string) => {
    console.log('Parsed Data:', data);
    await inviteUsers({ campaignId: id ?? '0', data });
    message.success('CSV file parsed successfully!');
  };

  const copyToClipboard = (link: string) => {
    navigator.clipboard.writeText(link);
    message.success('Link copied to clipboard');
  };
  const handleNavigateToCampaign = () => {
    window.location.href = `${process.env.REACT_APP_BASE_URL_FE}raise/${campaignId}/${user?.id}`;
  };
  const openContactsModal = (campaignId: string) => {
    setSelectedContactsCampaignId(campaignId);
    setContactsModalVisible(true);
  };

  const closeContactsModal = () => {
    setContactsModalVisible(false);
    setSelectedContactsCampaignId(null);
  };

  const openLeaderBoardModal = (campaignId: string) => {
    setSelectedContactsCampaignId(campaignId);
    setLeaderBoardModalVisible(true);
  };

  const closeLeaderBoardModal = () => {
    setLeaderBoardModalVisible(false);
    setSelectedContactsCampaignId(null);
  };

  const columns = [
    {
      title: 'Campaign Name',
      dataIndex: 'title',
      key: 'title',
      sorter: (a: any, b: any) => a.title.localeCompare(b.title),
    },
    {
      title: 'Invite Contacts',
      dataIndex: 'orderReport',
      key: 'orderReport',
      render: (text: string, record: Campaign) => (
        <div>
          <CSVUploader
            onDataParsed={(data) => handleDataParsed(data, record.id)}
            loading={isUploadingCSV}
          />
        </div>
      ),
    },
    {
      title: 'View Campaign',
      dataIndex: 'userReport',
      key: 'userReport',
      render: (text: string, record: Campaign) => (
        <Button
          icon={<CopyOutlined />}
          onClick={() =>
            copyToClipboard(
              `${process.env.REACT_APP_BASE_URL_FE}raise/${record.id}/${user?.id}`
            )
          }
        >
          Copy link
        </Button>
      ),
    },
    {
      title: 'View Contacts',
      dataIndex: 'contacts',
      key: 'contacts',
      render: (text: string, record: Campaign) => (
        <Button onClick={() => openContactsModal(record.id)}>
          View Contacts
        </Button>
      ),
    },
    {
      title: 'Is Closed',
      dataIndex: 'is_closed',
      key: 'is_closed',
      sorter: (a: any, b: any) => Number(a.is_closed) - Number(b.is_closed),
      render: (text: boolean) => (text ? 'Yes' : 'No'),
    },
    {
      title: 'View Leader Board',
      dataIndex: 'View Leader Board',
      key: 'View Leader Board',
      render: (text: string, record: Campaign) => (
        <Button onClick={() => openLeaderBoardModal(record.id)}>
          View Leader Board
        </Button>
      ),
    },
  ];
console.log(user?.id,campaignId,'hij')
  return (
    <>
      {!campaignId ? (
        <>
          <div
            style={{
              display: 'flex',
              marginTop: '-60px',
              marginBottom: '60px',
              width: '100%',
              overflowX: 'auto',
            }}
          >
            {isLoading ? (
              <Spin />
            ) : (
              data?.map((campaign) => {
                const amountRaised = campaign.money_raised
                  ? `$${formatWithCommas(campaign.me_amount)}`
                  : 'Not Raised';
                const endDate = convertUTCDateToLocalDate(
                  new Date(campaign?.end_date) as Date
                );

                return (
                  <CampaignCard
                    key={campaign.id}
                    name={campaign.title}
                    amountRaised={amountRaised}
                    endDate={
                      isNaN(endDate.getTime())
                        ? 'Invalid Date'
                        : endDate.toLocaleString()
                    }
                    status={campaign.is_closed ? 'Closed' : 'Active'}
                    onDetailsClick={() => {
                    
                      navigate(`/view-campaign/${campaign?.id}`, { state: { campaignId:campaign?.id  } })
                      //@ts-ignore
                      // setCampaignId(campaign?.id);
                    }}
                  />
                );
              })
            )}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                width: 740,
              }}
            >
              <SvgIcon />
            </div>
          </div>
          {/* <Table
            columns={columns}
            dataSource={data || []}
            pagination={false}
            rowKey="id"
            scroll={{ x: '100vw' }} // Enable horizontal scroll
          />
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={0}
            onChange={onPageChange}
          
            showSizeChanger={false}
          /> */}
        </>
      ) : (
        <div style={{ marginTop: '-60px' }}>
          <Button
            type="primary"
            shape="circle"
            icon={<FaArrowAltCircleLeft />}
            onClick={() => {
              setCampaignId(null);
            }}
          />
          <Title level={4}>
            Campaigns -{' '}
            {data?.find((campaign) => campaign.id === campaignId)?.title}
          </Title>
          <div className="campaign-detail">
            <div className="button-container">
              <Button
                type="primary"
                className="button"
                onClick={handleNavigateToCampaign}
              >
                Open My Store
              </Button>
              <Button
                type="primary"
                className="button"
                onClick={() =>
                  copyToClipboard(
                    `${process.env.REACT_APP_BASE_URL_FE}raise/${campaignId}/${user?.id}`
                  )
                }
              >
                Copy Link 
              </Button>
            </div>
            <div className="card-container">
              <Card>
                <Meta
                  avatar={
                    <div className="icon-container">
                      <div className="icon-circle">
                        <FontAwesomeIcon
                          icon={faSackDollar}
                          size="lg"
                          color="#FFBB38"
                        />
                      </div>
                    </div>
                  }
                  title={`$${formatWithCommas(
                    data?.find((campaign) => campaign.id === campaignId)
                      ?.money_raised
                  )}`}
                  description="Raised by Me"
                />
              </Card>
              <Card>
                <Meta
                  avatar={
                    <div className="icon-container purple">
                      <div className="icon-circle">
                        <FontAwesomeIcon
                          icon={faHandHoldingDollar}
                          size="lg"
                          color="#396AFF"
                        />
                      </div>
                    </div>
                  }
                  title={'23'}
                  description="Sold by Me"
                />
              </Card>
            </div>
          </div>
          <Title level={4}>
           Contacts
          </Title>
          <Card className='campaign-contacts'>
                <Meta 
                avatar={
                  <Meta 
                  title={studentData?.count ?? "0"} 
                  description="Count" 
                />
                }
                title={<>
                <CSVUploader
                button={<Button  className="button" danger >
                Upload Contacts CSV
              </Button>}
            onDataParsed={(data) => handleDataParsed(data, campaignId)}
            loading={isUploadingCSV}
          />
              <Button  className="button" danger onClick={() => openContactsModal(campaignId)}>
                View Contacts
              </Button>
                </>} description={<Paragraph style={{marginBottom:0}}>
                  Not sure what to include?
                  <CSVLink
                    data={`name,email
          John Doe,jdoe@example.com`}
                  >
                    {' '}
                    Download our sample CSV
                  </CSVLink>
                  ;
                </Paragraph>
              }
            />
          </Card>
          <Title level={3}>Recent Orders</Title>
          <OrdersTable user_id={user?.id} campaign_id={campaignId}/>
        </div>
      )}
      {selectedContactsCampaignId && (
        <ContactsModal
          isMobile={isMobile}
          campaignId={selectedContactsCampaignId}
          visible={contactsModalVisible}
          onClose={closeContactsModal}
        />
      )}
      {leaderBoardModalVisible && (
        <LeaderBoardModal
          isMobile={isMobile}
          campaignId={selectedContactsCampaignId ?? ''}
          visible={leaderBoardModalVisible}
          onClose={closeLeaderBoardModal}
        />
      )}
      {/* <Paragraph>
        Not sure what to include?
        <CSVLink 
         data ={`name,email
John Doe,jdoe@example.com`}
         >
          {''}Download our sample CSV
         </CSVLink>
        
         
         ;



       </Paragraph> */}
    </>
  );
};

export default StudentCampaigns;
