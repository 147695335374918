import React, { useEffect } from 'react';
import { Card, Row, Col, Typography, Divider, Space, Button, Spin } from 'antd';
import {
  CheckCircleOutlined,
  CheckOutlined,
  CodeSandboxOutlined,
  CreditCardFilled,
  DollarOutlined,
  FileImageOutlined,
  MessageOutlined,
  MinusOutlined,
  PayCircleOutlined,
} from '@ant-design/icons';
import Meta from 'antd/es/card/Meta';
import '../styles/ProfileSetup.scss';
import { useLazyGetUserQuery } from '../services/admin/adminAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingDollar, faSackDollar } from '@fortawesome/free-solid-svg-icons';
import { convertUTCDateToLocalDate, formatWithCommas } from '../utils';
import Hand from '../assets/svg/Hand';
import Give from '../assets/svg/Give';
import OrdersTable from './OrdersTable';
import DashboardOrdersTable from './DashboardOrdersTable';
import CampaignCard from './CampaignCard';
import { useGetCampaignsQuery } from '../services/admin/inventoryApi';
import { useNavigate } from 'react-router-dom';
import ActiveCampaignCard from './ActiveCampaignCard';
import { useDispatch } from 'react-redux';
import { setActiveTab } from '../slices/admin/activeTabSlice';

const { Text, Title } = Typography;

const ProfileSetup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetCampaignsQuery({
    skip: 0,
    limit: 1000,
  });
  const [getUser, { data: user }] = useLazyGetUserQuery();

  useEffect(() => {
    getUser();
  }, [getUser]);
console.log(user)
  return (
    <div style={{marginTop:"-60px",
    display:"flex",
    flexDirection:"column"}}>
       {/* @ts-ignore */}
    { data?.filter((item)=>{
             return   !item?.is_closed
              } )?.length>0&&   <Title level={3}>Active Campaigns</Title>}
  <div
            style={{
              display: 'flex',
              marginBottom: '10px',
              width: '100%',
              overflowX: 'auto',
            }}
          >

{isLoading ? (
              <Spin />
            ) : (
              data?.filter((item)=>{
             return   !item.is_closed
              } ).map((campaign) => {
                const amountRaised = campaign.money_raised
                  ? `$${formatWithCommas(campaign.me_amount)}`
                  : 'Not Raised';
                const endDate = convertUTCDateToLocalDate(
                  new Date(campaign?.end_date) as Date
                );

                return (
                  <ActiveCampaignCard
                    key={campaign.id}
                    link={campaign.campaign_link}
                    title={campaign.title}
                    blurb={campaign.blurb}
                    campaignId={campaign?.id}
                    userId={user?.id ?? 0}
                    name={campaign.title}
                    amountRaised={amountRaised}
                    endDate={
                      isNaN(endDate.getTime())
                        ? 'Invalid Date'
                        : endDate.toLocaleString()
                    }
                    status={campaign.is_closed ? 'Closed' : 'Active'}
                    onDetailsClick={() => {
                    
                      navigate(`/view-campaign/${campaign?.id}`, { state: { campaignId:campaign?.id  } })
                      //@ts-ignore
                      // setCampaignId(campaign?.id);
                    }}
                  />
                );
              })
            )}
          </div>


    <div className='user-cards'>
      <div style={{
         
          display: 'flex',
          flexDirection: 'column',
        }}>
      <Title level={3}>Profile Setup</Title>
      <Card
        title={<Hand />}
        style={{
          width: 'fit-content',
          display: 'flex',
          flexDirection: 'row-reverse',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            gap: 15,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Meta
            title={
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 15,
              }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 15,
                  
                }}
              >
                <Button
                  type="primary"
                  shape="circle"
                  icon={
                    <MessageOutlined
                      style={{
                        color: '#ffbb38', 
                        paddingLeft: 1,
                        paddingRight: 1,
                        fontSize: '20px',
                       
                      }}
                    />
                  }
                  onClick={() => { dispatch(setActiveTab(`tab5` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));}}
                  style={{
                    backgroundColor: '#FFF5D9',
                    padding: 20,
                     boxShadow:'none'
                  }}
                />
                <Title
                  style={{
                    fontSize: 14,
                  }}
                >
                  Blurb Filled Out
                </Title>
                </div>
               {user?.blurb ? <CheckOutlined  style={{
                  color:'green'
                }}/>:  <MinusOutlined  />}
              </div>
            }
          />
          <Meta
            title={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 15,
                }}
              >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 15,
                }}
              >
                <Button
                  type="primary"
                  shape="circle"
                  icon={
                    <DollarOutlined
                      style={{
                        color: '#396AFF',
                        borderRadius: 50,
                        fontSize: '20px',
                      }}
                    />
                  }
                  onClick={() => { dispatch(setActiveTab(`tab5` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));}}

                  style={{
                    backgroundColor: '#E7EDFF',
                    padding: 20,
                    boxShadow:'none'
                  }}
                />
                <Title
                  style={{
                    fontSize: 14,
                  }}
                >
                  Goal Set
                </Title>
                </div>
                {user?.raise_goal ? <CheckOutlined  style={{
                  color:'green'
                }}/>:  <MinusOutlined  />}
              </div>
            }
          />
          <Meta
            title={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 15,
                }}
              >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 15,
                }}
              >
                <Button
                  type="primary"
                  shape="circle"
                  icon={
                    <FileImageOutlined
                      style={{
                        color: '#16DBCC',
                        borderRadius: 50,
                        fontSize: '20px',
                      }}
                    />
                  }
                  onClick={() => { dispatch(setActiveTab(`tab5` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));}}

                  style={{
                    backgroundColor: '#DCFAF8',
                    padding: 20,
                    boxShadow:'none'
                  }}
                />
                <Title
                  style={{
                    fontSize: 14,
                  }}
                >
                  Photo Uploaded
                </Title>
                </div>
                {user?.primary_image_url ? <CheckOutlined  style={{
                  color:'green'
                }}/>:  <MinusOutlined  />}
              </div>
            }
          />
        </div>
      </Card>
      </div>
      <div style={{
         
         display: 'flex',
         flexDirection: 'column',
       }}>
      <Title level={3}>Campaign Summary</Title>
      <Card
        title={<Give />}
        style={{
          width: 'fit-content',
          display: 'flex',
          flexDirection: 'row-reverse',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            gap: 15,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Meta
            title={
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 15,
              }}>
                   <Button
                  type="primary"
                  shape="circle"
                  icon={
                  <CodeSandboxOutlined
                  style={{
                    color: '#396AFF',
                    borderRadius: 50,
                    fontSize: '20px',
                  }}
                  
                  />
                }
                onClick={() => { dispatch(setActiveTab(`tab9` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));}}

                style={{
                  backgroundColor: '#E7EDFF',
                  padding: 25,
                  boxShadow:'none'
                }}
                  />
                  
                 
                
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  
                 
                }}
              >
             
             <Title
                  style={{
                    fontSize: 12,
                    color: 'grey',
                    marginBottom: 0
                  }}
                >
                  Total Raised
                </Title>
                {/* @ts-ignore */}
                {`$${formatWithCommas(user?.sold_amount)}`}
                </div>
               
              </div>
            }
          />
          <Meta
            title={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  
                  alignItems: 'center',
                  gap: 15,
                }}
              >
                 <Button
                  type="primary"
                  shape="circle"
                  icon={
                    <FontAwesomeIcon
                          icon={faSackDollar}
                          size="lg"
                          color="#FFBB38"
                        
                        />
                  }
                  onClick={() => { dispatch(setActiveTab(`tab9` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));}}

                  style={{
                    backgroundColor: '#FFF5D9',
                    padding: 25,
                    boxShadow:'none'
                  }}
                />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
               
                }}
              >
               
                <Title
                  style={{
                    fontSize: 12,
                    color: 'grey',
                    marginBottom: 0
                  }}
                >
                  Total Sales
                </Title>
                {/* @ts-ignore */}
                {user?.count_amount}
                </div>
               
              </div>
            }
          />
         
        </div>
      </Card>
      </div>
    </div>
    <Title level={3}>Recent Orders</Title>
    <DashboardOrdersTable  user_id={user?.id}/>
    </div>
  );
};

export default ProfileSetup;
