// MainLayout.tsx
import React, { useState, useEffect, useCallback } from 'react';
import { Layout, Menu, Button, Drawer, MenuProps } from 'antd';
import { MenuOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { FlagFilled, HomeFilled, LoginOutlined, ProfileFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from './slices/admin/adminUserSlice';
import logo from './assets/images/logo.png';
import { useLazyGetUserQuery } from './services/admin/adminAuth';
import { RootState } from './store';
import { setActiveTab } from './slices/admin/activeTabSlice';
import {

  ShoppingOutlined,
  AppstoreOutlined,
  UsergroupAddOutlined,
  BulbOutlined,
  ShoppingCartOutlined,
  BarChartOutlined,
} from '@ant-design/icons';
import './styles/Dashboard.scss';

const { Header, Content, Sider } = Layout;

const scopes = ['internal_admin', 'rep_main', 'internal_support', 'rep_sub', 'sponsor', 'sponsor_sub', 'rep'];

const MainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const activeTab = useSelector((state: RootState) => state.activeTab.activeTab);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getUser, { data: user }] = useLazyGetUserQuery();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992);

  useEffect(() => {
    getUser();

    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 992);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [getUser]);

  const handleMenuClick = useCallback(
    (e: any) => {
      if (e.key === '9') {
        dispatch(signOut());
        navigate('/signin');
      } else {
        if (!isDesktop) {
          setDrawerVisible(false);
        }
        dispatch(setActiveTab(`${e.key}` as 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9' | 'tab11'));
        navigate(`/dashboard`)
      }
    },
    [dispatch, navigate, isDesktop]
  );

  const items2: MenuProps['items'] = [
    {
      key: 'sub1',
      icon: React.createElement(NotificationOutlined),
      label: 'Manage',
      children: [
        ...(user?.scope === 'internal_admin'
          ? [
              { key: 'tab1', label: 'Products', icon: React.createElement(ShoppingOutlined) },
              { key: 'tab2', label: 'Catalogs', icon: React.createElement(AppstoreOutlined) },
            ]
          : []), // Only show Products and Catalogs if scope is internal_admin
        { key: 'tab3', label: 'Groups', icon: React.createElement(UsergroupAddOutlined) },
        { key: 'tab4', label: 'Campaigns', icon: React.createElement(BulbOutlined) },
        { key: 'tab6', label: 'Users', icon: React.createElement(UserOutlined) },
        { key: 'tab7', label: 'Orders', icon: React.createElement(ShoppingCartOutlined) },
        { key: 'tab8', label: 'Reports', icon: React.createElement(BarChartOutlined) },
      ],
    },
    {
      key: 'sub3',
      icon: React.createElement(UserOutlined),
      label: 'Account',
      children: [
        { key: 'tab5', label: 'Profile', icon: React.createElement(ProfileFilled) },
        { key: 'tab11', label: 'Dashboard', icon: React.createElement(HomeFilled) },
        { key: 'tab9', label: 'Campaigns', icon: React.createElement(FlagFilled) },
        { key: '9', label: 'Sign Out', icon: React.createElement(LoginOutlined) },
      ],
    },
  ];

  return (
    <Layout className={"dashboard"}>
      {isDesktop &&(
        <Sider width={200} className="sider">
            <div style={{ padding: '16px', textAlign: 'center' , backgroundColor: "white"}}>
          <img
            src={logo} alt="Company Logo"
            style={{ width: '100%', maxWidth: '120px' }}
          />
        </div>
          <Menu
            mode="inline"
            defaultSelectedKeys={[activeTab]}
            defaultOpenKeys={['sub1', 'sub3']}
            style={{ height: '100%', borderRight: 0 }}
            items={user && scopes.includes(user?.scope) ? items2 : items2.filter(item => item?.key === 'sub3')}
            onClick={handleMenuClick}
          />
        </Sider>
      )}
      <Layout>
        <Header className={isDesktop?"dashboard-header":"dashboard-header-mobile"}>
          <div className="header-content">
            {!isDesktop && (
              <Button
                className="menu-toggle"
                type="primary"
                icon={<MenuOutlined />}
                onClick={() => setDrawerVisible(true)}
                style={{ marginRight: '16px' }}
              />
            )}
          </div>
        </Header>
        <Content className={isDesktop?"content":"mainLayoutContent"}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
