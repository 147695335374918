// src/components/OrderSummary.tsx

import React from 'react';
import CurrencyInput from 'react-currency-input-field'; // Ensure this package is installed and imported
import { formatPrice } from '../utils';
import { useParams } from 'react-router-dom';
import { useGetCampaignForUserQuery } from '../services/campaign';
// Ensure you create a corresponding SCSS file

interface OrderSummaryProps {
  donationInput: string;
  shippingAmount: number;
  processingFee: number;
  totalAmount: number;
  handleDonationChange: (value: string) => void;
  subTotal: number;
  showInput?: boolean;
}


const OrderSummary: React.FC<OrderSummaryProps> = ({
  donationInput,
  showInput = true,
  shippingAmount,
  processingFee,
  totalAmount,
  subTotal,
  handleDonationChange,
}) => {
  const { campaign_id ,user_id} = useParams<{ campaign_id: string,user_id:string }>();
  const { data, isLoading, isError } = useGetCampaignForUserQuery(
    campaign_id || '0',
    {
      skip: !campaign_id,
    }
  );
  return (
    <>
      <div className="subtotal">
                <p>Subtotal</p>
                <p>
                  $
                  {formatPrice(
                    subTotal
                  )}
                </p>
              </div>
    {data?.donations_enabled&&  <div className="donation">
        <p>Donation</p>
       {showInput? <CurrencyInput
          className="donation-input"
          placeholder="Donation Amount"
          defaultValue={donationInput}
          decimalsLimit={2}
          onValueChange={(value) => {
            handleDonationChange(value ?? '');
          }}
          prefix="$"
        />:<p>${donationInput}</p>}
      </div>}
      <div className="shipping">
        <p>Shipping</p>
        <p>${formatPrice(shippingAmount)}</p>
      </div>
      <div className="shipping">
        <p>Processing Fee</p>
        <p>$ {formatPrice(processingFee)}</p>
      </div>
      <div className="grand-total">
        <p>Total</p>
        <p>${formatPrice(totalAmount + processingFee)}</p>
      </div>
    </>
  );
};

export default OrderSummary;
