import React, { useRef, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from 'react-router-dom';
import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store';
import Header from './components/Header';
import Banner from './components/Banner';
import FundraisingCard from './components/FundraisingCard';
import ShippingOptions from './components/ShippingOptions';
import ProductList from './components/ProductList';
import Checkout from './components/Checkout';
import DonationOptions from './components/DonationOptions';
import SignIn from './components/SignIn';
import Dashboard from './components/Dashboard';
import PrivateRoute from './components/PrivateRoute';
import ProductDetails from './components/ProductDetails';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import NotFound from './components/NotFound'; // Import NotFound component
import AddProduct from './components/AddProduct';
import EditProduct from './components/EditProduct';
import AddCatalog from './components/AddCatalog';
import EditCatalog from './components/EditCatalog';
import AddGroup from './components/AddGroup';
import EditGroup from './components/EditGroup';
import AddCampaign from './components/AddCampaign';
import EditCampaign from './components/EditCampaign';
import SignUp from './components/SignUp';
import TermsAndPolicy from './components/TermsAndPolicy';
import PrivacyPolicy from './components/PrivacyPolicy';
import { useGetCampaignForUserQuery } from './services/campaign';
import EditUser from './components/EditUser';
import ShareButtons from './components/ShareButtons';
import AddUser from './components/AddUser';
import LandingPage from './components/LandingPage'; // Import LandingPage component
import AppFooter from './components/Footer'; // Import the Footer component
import MainLayout from './MainLayout';
import { ConfigProvider } from 'antd';
import CampaignDetails from './components/CampaignDetails';

const App: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<string>('home');
  const { id, campaign_id, user_id } = useParams<{
    id: string;
    campaign_id: string;
    user_id: string;
  }>();
  const { data, error, isLoading, isError } = useGetCampaignForUserQuery(
    campaign_id || '0',
    {
      skip: !campaign_id,
    }
  );
  const donationOptionsRef = useRef<HTMLDivElement>(null);
  const handleOptionSelect = (option: string) => {
    if (option === 'donate' && donationOptionsRef.current) {
      donationOptionsRef.current.scrollIntoView({ behavior: 'smooth' });
      return
    }
    setSelectedOption(option);
  };

  return (
    <Provider store={store}>
      <ConfigProvider
    theme={{
      token: {
        // Seed Token
        colorPrimary: '#C31532',
        borderRadius: 15,
        padding: 16,

        // Alias Token
      },
      components: {
        Input: {
          borderRadius: 15,  // Specific border-radius for Input components
        
        },
        Card: {
          borderRadius: 25,  // Specific border-radius for Input components
        
        },
      },
    }}
  >
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <div className="App">
            <Routes>
              <Route path="/" element={<LandingPage />} />{' '}
              {/* Add this line for the landing page */}
              <Route
                path={'/raise/:campaign_id/:user_id'}
                element={
                  <>
                    <Header />
                    <Banner />
                    <FundraisingCard />
                    <ShippingOptions
                      onOptionSelect={handleOptionSelect}
                      selectedOption={selectedOption}
                    />
                   
                    <ProductList selectedOption={selectedOption} />
                    <div ref={donationOptionsRef}>
                    <DonationOptions />
                    </div>
                  </>
                }
              />
              <Route
                path={'/raise/:campaign_id'}
                element={
                  <>
                    <Header />
                    <Banner />
                    <FundraisingCard />
                    <ShippingOptions
                      onOptionSelect={handleOptionSelect}
                      selectedOption={selectedOption}
                    />
                   
                    <ProductList selectedOption={selectedOption} />
                    <div ref={donationOptionsRef}>
                    <DonationOptions />
                    </div>
                  </>
                }
              />
              <Route
                path="/raise/:campaign_id/:user_id/checkout"
                element={
                  <>
                    <Checkout />
                    <ShareButtons
                      schoolName={data?.title ?? ''}
                      blurb={data?.blurb ?? ''}
                    />
                  </>
                }
              />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signup/:id" element={<SignUp />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/dashboard"
                element={<PrivateRoute component={Dashboard} />}
              />
              <Route
                path="/product/:id"
                element={<PrivateRoute component={ProductDetails} />}
              />
              <Route
                path="/edit-product/:id"
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <EditProduct />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/edit-catalog/:id"
                element={
                  <PrivateRoute>
                    <MainLayout>
                      <EditCatalog />
                    </MainLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/edit-groups/:id"
                element={<PrivateRoute>
                  <MainLayout>
                    <EditGroup />
                  </MainLayout>
                </PrivateRoute>}
              />
              <Route
                path="/edit-user/:id"
                element={<PrivateRoute>
                  <MainLayout>
                    <EditUser />
                  </MainLayout>
                </PrivateRoute>}
              />
              <Route
                path="/add-product"
                element={<PrivateRoute>
                  <MainLayout>
                    <AddProduct />
                  </MainLayout>
                </PrivateRoute>}
              />{' '}
              {/* Add this line */}
              <Route
                path="/add-group"
                element={<PrivateRoute>
                  <MainLayout>
                    <AddGroup />
                  </MainLayout>
                </PrivateRoute>}
              />
              <Route
                path="/add-catalog"
                element={<PrivateRoute>
                  <MainLayout>
                    <AddCatalog />
                  </MainLayout>
                </PrivateRoute>}
              />
              <Route
                path="/add-campaign"
                element={<PrivateRoute>
                  <MainLayout>
                    <AddCampaign />
                  </MainLayout>
                </PrivateRoute>}
              />
              <Route
                path="/add-user"
                element={<PrivateRoute>
                  <MainLayout>
                    <AddUser />
                  </MainLayout>
                </PrivateRoute>}
              />
              <Route
                path="/edit-campaign/:id"
                element={<PrivateRoute>
                  <MainLayout>
                    <EditCampaign />
                  </MainLayout>
                </PrivateRoute>}
              />
              <Route
                path="/view-campaign/:campaign_id"
                element={<PrivateRoute>
                  <MainLayout>
                    <CampaignDetails />
                  </MainLayout>
                </PrivateRoute>}
              />
              <Route path="/terms" element={<TermsAndPolicy />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="*" element={<NotFound />} />{' '}
              {/* Catch-all route for 404 */}
            </Routes>
            <AppFooter /> {/* Add Footer here */}
          </div>
        </Router>
      </PersistGate>
      </ConfigProvider>
    </Provider>
  );
};

export default App;
