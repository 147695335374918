import React, { useEffect } from 'react';
import { Table, Pagination, Spin, message } from 'antd';
import { useGetCampaignsQuery } from '../services/admin/inventoryApi';
import usePagination from '../hooks/usePagination';
import { Campaign } from '../types.ts'; // Ensure correct import path
import { convertUTCDateToLocalDate } from '../utils';

const columns = [
  {
    title: 'Closed',
    dataIndex: 'is_closed',
    key: 'is_closed',
    sorter: (a: Campaign, b: Campaign) => Number(a.is_closed) - Number(b.is_closed),
    render: (text: boolean) => (text ? 'Yes' : 'No'),
  },
  {
    title: 'PreSale ID',
    dataIndex: 'pre_sale_id',
    key: 'pre_sale_id',
    sorter: (a: Campaign, b: Campaign) => a.pre_sale_id - b.pre_sale_id,
  },
  {
    title: 'Group',
    dataIndex: ['group', 'name'], // Accessing nested group name
    key: 'group.name',
    sorter: (a: Campaign, b: Campaign) => a.group.name.localeCompare(b.group.name),
  },
  {
    title: 'Campaign Name',
    dataIndex: 'title',
    key: 'title',
    sorter: (a: Campaign, b: Campaign) => a.title.localeCompare(b.title),
  },
 
  {
    title: 'End Date',
    dataIndex: 'end_date', // Adjust according to the actual data field
    key: 'end_date',
    sorter: (a: Campaign, b: Campaign) =>
      new Date(a.end_date).getTime() - new Date(b.end_date).getTime(),
    render: (text: string) => {
      const targetDate = convertUTCDateToLocalDate(new Date(text) as Date);
    
      return isNaN(targetDate.getTime()) ? 'Invalid Date' : targetDate.toLocaleString();
    },
  },
  {
    title: 'Money Raised',
    dataIndex: 'money_raised', // Adjust according to the actual data field
    key: 'money_raised',
    sorter: (a: Campaign, b: Campaign) => a.money_raised - b.money_raised,
    render: (amount: number) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(amount / 100);
    },
  },
];

interface CampaignsTableProps {
  handleRowClick: (record: Campaign) => void;
}

const CampaignsTable: React.FC<CampaignsTableProps> = ({ handleRowClick }) => {
  const { currentPage, pageSize, onPageChange } = usePagination();

  const { data, error, isLoading } = useGetCampaignsQuery({
    skip: (currentPage - 1) * pageSize,
    limit: pageSize,
  });

  useEffect(() => {
    if (error) {
      message.error('Failed to load campaigns');
    }
  }, [error]);

  return (
    <>
      {isLoading ? (
        <Spin tip="Loading..." />
      ) : (
        <>
          <Table
            columns={columns}
            dataSource={data || []}
            pagination={false}
            rowKey="id"
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
            rowClassName="clickable-row"
          />
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={0}
            onChange={onPageChange}
            showSizeChanger={false}
          />
        </>
      )}
    </>
  );
};

export default CampaignsTable;
