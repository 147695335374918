import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

const AppFooter: React.FC = () => {
  return (
    <Footer style={{ textAlign: 'center', backgroundColor: '#f0f2f5' }}>
    ©2024 Fundraising Manager | 
    <a href="mailto:sth@fundraisingmanager.com"> Contact</a> | {/* Update this line */}
    <a href="/terms">Terms of Service</a> | 
    <a href="/privacy"> Privacy Policy</a>
  </Footer>
  );
};

export default AppFooter;
