import React, { useState } from 'react';
import { Upload, Button, message, Spin, Modal, Form, Input, Space, Tabs } from 'antd';
import { UploadOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
import { RcFile, UploadProps } from 'antd/es/upload/interface';

const { TextArea } = Input;
const { TabPane } = Tabs;

interface ParsedData {
  email_address: string;
  name: string;
}

interface CSVUploaderProps {
  onDataParsed: (data: ParsedData[]) => void;
  loading: boolean;
  button?: React.ReactNode;
}

const CustomCSVUploader: React.FC<CSVUploaderProps> = ({ onDataParsed, loading, button }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [bulkInput, setBulkInput] = useState('');

  const handleFileUpload = (file: RcFile) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        const formattedData = result.data.map((row: any) => ({
          email_address: row.email,
          name: row.name,
        }));
        onDataParsed(formattedData);
      },
      error: (error) => {
        message.error(`Error parsing CSV file: ${error.message}`);
      },
    });
  };

  const customRequest: UploadProps['customRequest'] = (options) => {
    const { file, onSuccess } = options;
    setTimeout(() => {
      onSuccess && onSuccess("ok");
    }, 0);
    handleFileUpload(file as RcFile);
  };

  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  const validateEmail = (email: string) => {
    // Basic email regex for validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const onFinish = (values: any) => {
    const isValid = values.contacts.every((contact: any) => validateEmail(contact.email));

    if (!isValid) {
      message.error('Please enter valid email addresses.');
      return;
    }

    const formattedData = values.contacts.map((contact: any) => ({
      email_address: contact.email,
      name: contact.name,
    }));
    onDataParsed(formattedData);
    form.resetFields();
    handleModalClose();
  };

  const handleBulkSubmit = () => {
    const lines = bulkInput.trim().split('\n');
    const formattedData: ParsedData[] = [];

    for (let line of lines) {
      const [nameOrPhone, email] = line.split(',').map((item) => item.trim());

      if (!email || !validateEmail(email)) {
        message.error('Each entry must contain a valid email address.');
        return;
      }

      formattedData.push({
        name: nameOrPhone.includes('@') ? '' : nameOrPhone,
        email_address: nameOrPhone.includes('@') ? nameOrPhone : email,
      });
    }

    onDataParsed(formattedData);
    setBulkInput('');
    handleModalClose();
  };

  return (
    <>
      <Upload
        accept=".csv"
        showUploadList={false}
        customRequest={customRequest}
      >
        {button ? button : <Button icon={<UploadOutlined />}>Upload CSV</Button>}
        {loading && <Spin style={{ marginLeft: 10 }} />}
      </Upload>
      
      <Button   onClick={handleModalOpen} style={{ padding: '9px 11px',marginLeft: 10, height:'auto',color: "#ff4d4f", borderColor: "#ff4d4f", width:'180px'}}>
        Add Contacts Manually
      </Button>
      <Modal
        title="Add Contacts"
        visible={isModalOpen}
        onCancel={handleModalClose}
        footer={null}
        width={600}
      >
        <Tabs defaultActiveKey="1">
          {/* Form Entry Section */}
          <TabPane tab="Add Contacts One by One" key="1">
            <Form form={form} name="manual_contact_form" onFinish={onFinish} autoComplete="off">
              <Form.List name="contacts">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                        <Form.Item
                          {...restField}
                          name={[name, 'name']}
                          rules={[{ required: true, message: 'Missing name' }]}
                        >
                          <Input placeholder="Name" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'email']}
                          rules={[
                            { required: true, message: 'Missing email' },
                            { type: 'email', message: 'Invalid email format' }
                          ]}
                        >
                          <Input placeholder="Email" />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add field
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
          {/* Bulk Entry Section */}
          <TabPane tab="Bulk Entry" key="2">
            <Form.Item label="Enter each contact's phone or email on a new line">
              <TextArea
                rows={4}
                placeholder="test,test@gmail.com test2,test@gmail.com"
                value={bulkInput}
                onChange={(e) => setBulkInput(e.target.value)}
              />
            </Form.Item>
            <Button type="primary" onClick={handleBulkSubmit}>
              Submit
            </Button>
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default CustomCSVUploader;
