import React from 'react';
import '../styles/AddProduct.scss';
import ImageUploader from './ImageUploader';
import { Switch, Form, Input, Button, Typography, Tooltip } from 'antd';
import CurrencyInput from 'react-currency-input-field';
import { useForm, Controller } from 'react-hook-form';
import { useAddProductMutation } from '../services/admin/inventoryApi';
import { useNavigate } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Title } = Typography;

const AddProduct: React.FC = () => {
  const { register, handleSubmit, control, formState: { errors }, setValue, watch } = useForm({
    defaultValues: {
      primary_image_url: '',
      fulfilment_type: 'STS',
      cost: 0,
      name: '',
      blurb: '',
      internal_name: '',
      internal_id: '',
      sku: ''
    }
  });

  const primaryImageUrl = watch('primary_image_url');
  const [addProduct, { isLoading, isError, isSuccess }] = useAddProductMutation();
  const navigate = useNavigate();

  const onSubmit = async (data: any) => {
    try {
      await addProduct(data).unwrap();
      console.log('Product added successfully:', data);
      navigate(-1);
    } catch (error) {
      console.error('Failed to add product:', error);
    }
  };

  const handleImageUpload = (imageUrl: string) => {
    setValue('primary_image_url', imageUrl, { shouldValidate: true });
  };

  return (
    <div className="add-product-container">
      <Title level={2}>Add Product</Title>
      <Form onFinish={handleSubmit(onSubmit)} layout="vertical" className="add-product-form">
        <Form.Item
          label="Primary Image URL"
          validateStatus={errors.primary_image_url ? 'error' : ''}
          help={errors.primary_image_url ? errors.primary_image_url.message : ''}
        >
          <Controller
            name="primary_image_url"
            control={control}
            rules={{ required: 'Primary image is required' }}
            render={({ field }) => (
              <>
                {primaryImageUrl && (
                  <img src={primaryImageUrl} alt="Primary" style={{ width: '100px', height: '100px' }} />
                )}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                <ImageUploader onUpload={(imageUrl) => handleImageUpload(imageUrl)}
                width={512}
                height={512} />
                 <Tooltip title="For best results, please upload a 512 × 512 png or jpg">
                    <QuestionCircleOutlined style={{ marginLeft: 8,marginTop:12, color: '#1890ff' }} />
                  </Tooltip>
                </div>

              </>
            )}
          />
        </Form.Item>

        <Form.Item
          label="Fulfilment Type"
          validateStatus={errors.fulfilment_type ? 'error' : ''}
          help={errors.fulfilment_type ? errors.fulfilment_type.message : ''}
        >
          <Controller
            name="fulfilment_type"
            control={control}
            render={({ field }) => (
              <div className="switch-container">
                <span>STH</span>
                <Switch
                  checked={field.value === 'STS'}
                  onChange={(checked) => field.onChange(checked ? 'STS' : 'STH')}
                />
                <span>STS</span>
              </div>
            )}
          />
        </Form.Item>

        <Form.Item
  label="Cost"
  validateStatus={errors.cost ? 'error' : ''}
  help={errors.cost ? errors.cost.message : ''}
>
  <Controller
    name="cost"
    control={control}
    rules={{ required: 'Cost is required' }}
    render={({ field }) => (
      
      <CurrencyInput
     className='ant-input css-dev-only-do-not-override-zg0ahe ant-input-outlined'
      placeholder="Please enter Cost"
      defaultValue={field.value}
      decimalsLimit={2}
      onValueChange={(value) => {
        const centsValue = value ? parseFloat(value) * 100 : 0;
        field.onChange( centsValue );
      }}
      prefix="$"
    />
    )}
  />
</Form.Item>

        <Form.Item
          label="Name"
          validateStatus={errors.name ? 'error' : ''}
          help={errors.name ? errors.name.message : ''}
        >
          <Controller
            name="name"
            control={control}
            rules={{ required: 'Name is required' }}
            render={({ field }) => (
              <Input
                type="text"
                {...field}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label="Internal Name"
          validateStatus={errors.internal_name ? 'error' : ''}
          help={errors.internal_name ? errors.internal_name.message : ''}
        >
          <Controller
            name="internal_name"
            control={control}
            rules={{ required: 'Internal Name is required' }}
            render={({ field }) => (
              <Input
                type="text"
                {...field}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label="Internal ID"
          validateStatus={errors.internal_id ? 'error' : ''}
          help={errors.internal_id ? errors.internal_id.message : ''}
        >
          <Controller
            name="internal_id"
            control={control}
            rules={{ required: 'Internal ID is required' }}
            render={({ field }) => (
              <Input
                type="text"
                {...field}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label="Sku"
          validateStatus={errors.sku ? 'error' : ''}
          help={errors.sku ? errors.sku.message : ''}
        >
          <Controller
            name="sku"
            control={control}
            rules={{ required: 'sku is required' }}
            render={({ field }) => (
              <Input
                type="text"
                {...field}
              />
            )}
          />
        </Form.Item>

        <Form.Item
          label="Blurb"
          validateStatus={errors.blurb ? 'error' : ''}
          help={errors.blurb ? errors.blurb.message : ''}
        >
          <Controller
            name="blurb"
            control={control}
            rules={{ required: 'Blurb is required' }}
            render={({ field }) => (
              <TextArea
                {...field}
                rows={4}
              />
            )}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>Add Product</Button>
        </Form.Item>

        {isError && <p>Failed to add product. Please try again.</p>}
        {isSuccess && <p>Product added successfully!</p>}
      </Form>
    </div>
  );
};

export default AddProduct;
