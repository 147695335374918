import React, { useEffect } from 'react';
import { Table, Pagination, Spin, message } from 'antd';
import { useGetGroupsQuery } from '../services/admin/inventoryApi';
import  usePagination  from '../hooks/usePagination';
import { Group, Item } from '../types.ts'; // Ensure correct import path


const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: 'Description',
    dataIndex: 'blurb',
    key: 'blurb',
    sorter: (a: any, b: any) => a.blurb.localeCompare(b.blurb),
  },
  {
    title: 'Rep Group',
    dataIndex: 'is_rep_group',
    key: 'is_rep_group',
    sorter: (a: any, b: any) => Number(a.is_rep_group) - Number(b.is_rep_group),
    render: (text: boolean) => (text ? 'Yes' : 'No'),
  },
  // Add more columns as necessary
];
interface GroupsTableProps {
  handleRowClick: (record: Group) => void;
}
const GroupsTable: React.FC<GroupsTableProps> = ({handleRowClick}) => {
  const { currentPage, pageSize, onPageChange } = usePagination();

  const { data, error, isLoading } = useGetGroupsQuery({
    skip: (currentPage - 1) * pageSize,
    limit: pageSize,
    //@ts-ignore
    is_rep_group:null
  });

  useEffect(() => {
    if (error) {
      message.error('Failed to load groups');
    }
  }, [error]);

  return (
    <>
      {isLoading ? (
        <Spin tip="Loading..." />
      ) : (
        <>
          <Table
            columns={columns}
            dataSource={data || []}
            pagination={false}
            rowKey="id"
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
              
            })}
             rowClassName="clickable-row"
          />

          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={0}
            onChange={onPageChange}
            showSizeChanger={false}
          />
        </>
      )}
    </>
  );
};

export default GroupsTable;
