// components/NotFound.tsx
import React from 'react';
import  '../styles/NotFound.scss';

const NotFound: React.FC = () => {
  return (
    <div className='notFound'>
      <h2 className='title'>404 - Page Not Found</h2>
      <p className='message'>Sorry, the page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFound;
