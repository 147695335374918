// src/components/CartItem.tsx

import React from 'react';
import { Product } from '../types.ts';
import { formatPrice } from '../utils';

interface CartItemProps {
    showButtons?: boolean;
  item: Product;
  handleRemoveFromCart: (id: string) => void;
  handleAddToCart: () => void;
}


const CartItem: React.FC<CartItemProps> = ({
    showButtons=true,
  item,
  handleRemoveFromCart,
  handleAddToCart,
}) => {
  return (
    <li key={item.id}>
      <div className="item-info">
        <img src={item.primary_image_url} alt={item.title} />
        <div>
          <h3 style={{textAlign: "justify"}}>
            {item.title?.length > 20 ? item.title.substring(0, 20) + '...' : item.title}
          </h3>
          <p style={{textAlign: "justify"}}>${formatPrice(item.cost)}</p>
        </div>
      </div>
      <div className="item-action-container">
        <div className="item-actions remove-margin">
         {showButtons&& <button
            className="action-button"
            onClick={() => handleRemoveFromCart(item.id)}
          >
            -
          </button>}
          <span className="quantity">{item.quantity}</span> {!showButtons&&"x"}
       {showButtons&&   <button
            className="action-button"
            onClick={() => handleAddToCart()}
          >
            +
          </button>}
        </div>
        <div className="item-price remove-width">
         {item?.quantity?formatPrice(item?.quantity * item.cost) : "$0" } 
        </div>
      </div>
    </li>
  );
};

export default CartItem;
