import React, { useEffect } from 'react';
import { Table, Pagination, Spin, message } from 'antd';
import { useGetInventoryProductsQuery } from '../services/admin/inventoryApi';
import usePagination from '../hooks/usePagination';
import { Item } from '../types.ts'; // Ensure correct import path
import { formatPrice } from '../utils';
import "../styles/ProductsTable.scss"; 

const truncateText = (text: string, wordLimit: number) => {
  const words = text.split(' ');
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(' ') + '...';
  }
  return text;
};

const columns = [
  {
    title: 'Internal Name',
    dataIndex: 'internal_name',
    key: 'internal_name',
    sorter: (a: any, b: any) => a.internal_name.localeCompare(b.internal_name),
  },
  {
    title: 'Internal ID',
    dataIndex: 'internal_id',
    key: 'internal_id',
    sorter: (a: any, b: any) => a.internal_id.localeCompare(b.internal_id),

  },
  {
    title: 'Description',
    dataIndex: 'blurb',
    key: 'blurb',
    sorter: (a: any, b: any) => a.blurb.localeCompare(b.blurb),
    render: (text: string) => (
      <p className="description-column"   dangerouslySetInnerHTML={{ __html: truncateText(text, 20) }}></p>
    ),
  },
  {
    title: 'Fulfilment',
    dataIndex: 'fulfilment_type',
    key: 'fulfilment_type',
    sorter: (a: any, b: any) => a.fulfilment_type.localeCompare(b.fulfilment_type),
  },
  {
    title: 'Primary Image URL',
    dataIndex: 'primary_image_url',
    key: 'primary_image_url',
    render: (text: string) => (
      <img src={text} alt="Product" style={{ width: '100px', height: '100px' }} />
    ),
  },
];

interface ProductsTableProps {
  handleRowClick: (record: Item) => void;
}

const ProductsTable: React.FC<ProductsTableProps> = ({ handleRowClick }) => {
  const { currentPage, pageSize, onPageChange } = usePagination();

  const { data, error, isLoading } = useGetInventoryProductsQuery({
    skip: (currentPage - 1) * pageSize,
    limit: pageSize,
  });

  useEffect(() => {
    if (error) {
      message.error('Failed to load products');
    }
  }, [error]);

  return (
    <div className="products-table-container">
      {isLoading ? (
        <Spin tip="Loading..." />
      ) : (
        <>
          <Table
            columns={columns}
            dataSource={data || []}
            pagination={false}
            rowKey="id"
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
            rowClassName="clickable-row"
          />
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={0}
            onChange={onPageChange}
            showSizeChanger={false}
          />
        </>
      )}
    </div>
  );
};

export default ProductsTable;
