import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addItem, removeItem } from '../slices/campaign/cartSlice';
import { RootState } from '../store';
import '../styles/AddToCartButton.scss';
import { Product } from '../types.ts';

interface AddToCartButtonProps {
  product: Product;
  show?: boolean;
}

const AddToCartButton: React.FC<AddToCartButtonProps> = ({ product,show =true}) => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state: RootState) => state.cart.items);

  const handleAddToCart = () => {
    dispatch(addItem(product));
  };

  const handleRemoveFromCart = () => {
    dispatch(removeItem(product.id));
  };

  const getCartItemQuantity = () => {
    const item = cartItems.find(item => item.id === product.id);
    return item ? item.quantity : 0;
  };

  return (
    <div className="add-to-cart-button">
      {getCartItemQuantity() === 0 ? (
        <span onClick={handleAddToCart} className="action">Add to cart</span>
      ) : (
        <>
          <span onClick={handleRemoveFromCart} className="action">-</span>
          <span>{getCartItemQuantity()} {show&&'added'}</span>
          <span onClick={handleAddToCart} className="action">+</span>
          
        </>
      )}
    </div>
  );
};

export default AddToCartButton;
