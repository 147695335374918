import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import '../styles/Header.scss';
import logo from '../assets/images/logo.png';
import { FaShoppingCart } from 'react-icons/fa';
import { useGetCampaignForUserQuery } from '../services/campaign';
import { Helmet } from 'react-helmet';

const Header: React.FC = () => {
  const cartItems = useSelector((state: RootState) => state.cart.items);

  const { id, campaign_id, user_id } = useParams<{ id: string, campaign_id: string, user_id: string }>();
  const { data, isError } = useGetCampaignForUserQuery(campaign_id || '0', {
    skip: !campaign_id,
    
  });
  const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0);
  return (
    <header className="header-container">
       <Helmet>
        <title>BrandName Fundraising - {data?.title ?? ''}</title>
        <meta property="og:title" content={`BrandName Fundraising - ${data?.title ?? ''}`} />
        <meta property="og:description" content={`Support ${data?.title ?? ''}'s fundraising efforts`} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={data?.hero_image_url ?? ''} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="header">
        <img src={logo} alt="Logo" className="logo" />
        <nav className="nav">
        {isError|| data?.is_closed?<></>: 
         <Link to={`/raise/${campaign_id}/${user_id}/checkout`} className="shop-button">
            <FaShoppingCart className="cart-icon" />
            {totalItems > 0 && <span className="count">{totalItems}</span>}
            <span className="shop-text">Cart</span>
          </Link>
          }
        </nav>
      </div>
    </header>
  );
};

export default Header;
