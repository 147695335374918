import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store';

interface SignInRequest {
  email: string;
  password: string;
}

interface SignInResponse {
  data?: 
  {
    detail?: string;
  };
  access_token: string;
}

interface ResetPasswordRequest {
  token: string;
  password: string;
}
interface updatePasswordRequest {
  currentPassword: string;
  password: string;
  token: string;
}
interface UserResponse {
  email: string;
  is_active: boolean;
  raise_goal: number;
  blurb: string
  is_superuser: boolean;
  full_name: string;
  scope: string;
  in_leaderboard:boolean;
  id: number;
  primary_image_url: string;
  count_amount?:string;
  sold_amount?:string;
}
interface SignUpData {
  email: string;
  password: string;
  full_name: string;
  group_id: number;
}


const BASE_URL = process.env.REACT_APP_BASE_URL;

export const adminAuthApi = createApi({
  reducerPath: 'adminAuthApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).adminUser.token;
      headers.set('ngrok-skip-browser-warning', `true`);
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    signIn: builder.mutation<SignInResponse, SignInRequest>({
      query: (credentials) => ({
        url: 'auth/login/access-token',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          username: credentials.email,
          password: credentials.password,
        }).toString(),
      }),
    }),
    signUp: builder.mutation<SignInResponse, SignUpData>({
      query: (credentials) => ({
        url: 'users/signup',
        method: 'POST',
        body: credentials
      }),
    }),
    forgotPassword: builder.mutation({
      query: (email) => ({
        url: `auth/password-recovery/${email}`,
        method: 'POST',
      }),
    }),
    resetPassword: builder.mutation<void, ResetPasswordRequest>({
      query: ({ token, password }) => ({
        url: `auth/reset-password`,
        method: 'POST',
        body: { token, new_password: password },
      }),
    }),
    updatePassword: builder.mutation<void, updatePasswordRequest>({
      query: ({ currentPassword, password,token }) => ({
        url: `users/me/password`,
        method: 'PATCH',
        body: {current_password:currentPassword, new_password: password },
      }),
    }),
    getUser: builder.query<UserResponse, void>({
      query: () => ({
        url: 'users/me',
        method: 'GET',
      }),
    }),
    updateUser: builder.mutation<void, UserResponse>({
      query: (data) => ({
        url: `users/me`,
        method: 'PATCH',
        body: data,
      }),
    }),
  }),
});

export const { useSignInMutation, useForgotPasswordMutation, useResetPasswordMutation,useUpdatePasswordMutation, useLazyGetUserQuery, useSignUpMutation, useUpdateUserMutation } = adminAuthApi;
