import React, { useEffect } from 'react';
import { Table, Pagination, Spin, message } from 'antd';
import { useGetInventoryCatalogsQuery } from '../services/admin/inventoryApi';
import  usePagination from '../hooks/usePagination';
import { Catalog, Item } from '../types.ts'; // Ensure correct import path
import '../styles/CatalogsTable.scss';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: 'Description',
    dataIndex: 'blurb',
    key: 'blurb',
    sorter: (a: any, b: any) => a.blurb.localeCompare(b.blurb),
  },
  // Add more columns as necessary
];

interface CatalogsTableProps {
  handleRowClick: (record: Catalog) => void;
}
const CatalogsTable: React.FC<CatalogsTableProps> = ({handleRowClick}) => {
  const { currentPage, pageSize, onPageChange } = usePagination();

  const { data, error, isLoading } = useGetInventoryCatalogsQuery({
    skip: (currentPage - 1) * pageSize,
    limit: pageSize,
  });

  useEffect(() => {
    if (error) {
      message.error('Failed to load catalogs');
    }
  }, [error]);

  return (
    <>
      {isLoading ? (
        <Spin tip="Loading..." />
      ) : (
        <>
          <Table
            columns={columns}
            dataSource={data || []}
            pagination={false}
            rowKey="id"
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
             rowClassName="clickable-row"
          />
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={0}
            onChange={onPageChange}
            showSizeChanger={false}
          />
        </>
      )}
    </>
  );
};

export default CatalogsTable;