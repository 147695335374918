// File path: src/components/MessageCarousel.tsx

import React from 'react';
import Carousel from 'react-multi-carousel';
import '../styles/carousel.scss';
import 'react-multi-carousel/lib/styles.css'; // Import the carousel's default styles
import { Typography, Card } from 'antd';
import { useGetCampaignForUserQuery, useGetCampaignMessageQuery } from '../services/campaign';

const { Title, Paragraph } = Typography;

interface MessageCarouselProps {
  campaignId: string;
}

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


const MessageCarousel: React.FC<MessageCarouselProps> = ({ campaignId }) => {
  const { data:campaignData, error:campaigError, isLoading:campaignIsLoading, isError } = useGetCampaignForUserQuery(campaignId || '0', {
    skip: !campaignId,
  });
  const { data = [], error, isLoading } = useGetCampaignMessageQuery(campaignData?.id);

  if (!data?.length) return <></>;

  return (
    <div style={{ width: '100%',maxWidth: '1440px', margin: '0 auto', padding: '20px' }}>
      <Title level={3} style={{ textAlign: 'center' }}>
        Recent Orders
      </Title>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={true}
        responsive={responsive}
        ssr={true} // Server-side rendering
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        deviceType="desktop"
        dotListClass="custom-dot-list-style"
        itemClass="carousel"
      >
         {/* @ts-ignore */}
        {data?.map((message, index) => (
          <div key={index}>
            <Card style={{ margin: '0 10px', minHeight: '150px' }}>
              <Title level={4}>{message.name}</Title>
              <Paragraph>{message.message}</Paragraph>
            </Card>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default MessageCarousel;
