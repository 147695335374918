import React, { useEffect, useState } from 'react';
import { Modal, Table, Pagination, Spin } from 'antd';
import { useGetCampaignForUserQuery, useGetLeaderboardForPublicCampaignQuery } from '../services/campaign/index';
import { Campaign } from '../types.ts';
import { formatPrice } from '../utils';
import "../styles/PublicLeaderBoard.scss"
import { useParams } from 'react-router-dom';

interface PublicLeaderBoardProps {
  campaignId: string;
  visible: boolean;
  onClose: () => void;
  isMobile: boolean;
}

const PublicLeaderBoard: React.FC<PublicLeaderBoardProps> = ({ campaignId, visible, onClose, isMobile }) => {
  const { user_id } = useParams<{ id: string, campaign_id: string, user_id: string }>();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const { data: campaignData, error: campaignError, isLoading: campaignIsLoading } = useGetCampaignForUserQuery(campaignId || '0', {
    skip: !campaignId,
  });
  
  // Fetch leaderboard data based on the fetched campaign ID
  const { data: studentData = [], error, isLoading: isLoadingStudents } = useGetLeaderboardForPublicCampaignQuery({
    campaignId: campaignData?.id ?? '0',
    skip: (currentPage - 1) * pageSize,
    limit: pageSize,
  });
  
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 992);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: isDesktop ? '33%' : '50px', // Adjust column width for mobile
    },
    {
      title: 'Order Count',
      dataIndex: 'products_sold',
      key: 'products_sold',
      width: isDesktop ? '33%' : '50px', // Adjust column width for mobile
    },
    {
      title: 'Total Raised',
      dataIndex: 'amount_total_sold',
      key: 'amount_total_sold',
      render: (number: number) => (
        <p style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>${formatPrice(number)}</p>
      ),
      width: isDesktop ? '33%' : '50px', // Adjust column width for mobile
    },
  ];

  return (
    <Modal
      title="LeaderBoard"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={isDesktop ? '60%' : '100%'} // Full width for mobile
    >
      <Spin spinning={isLoadingStudents}>
        <Table
          columns={columns}
          dataSource={studentData || []}
          // @ts-ignore
          rowClassName={(record, index) => (String(record.user_id) === user_id ? 'first-row-highlight' : '')} // Apply class to the first row
          pagination={false}
          scroll={{ x: !isDesktop ? '100vw' : undefined }} // Enable horizontal scroll
        />
      </Spin>
    </Modal>
  );
};

export default PublicLeaderBoard;
