import React, { useState, useEffect } from 'react';
import '../styles/EditCampaign.scss';
import ImageUploader from './ImageUploader';
import dayjs from 'dayjs';
import CurrencyInput from 'react-currency-input-field';
import { Form, Input, Button, Typography, DatePicker, Switch, Drawer, List, Radio, message,QRCode, Segmented, Space,Checkbox } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import { useEditCampaignMutation, useGetCampaignsQuery, useGetGroupsQuery, useGetInventoryCatalogsQuery, useInviteStudentsMutation } from '../services/admin/inventoryApi';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { Group, Campaign, Catalog } from '../types.ts';
import { CopyOutlined, DownloadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import CSVUploader from './CSVUploader';
import ViewInvitesModal from './ViewInvitesModal';
import AccountsignupModal from './AccountsignupModal';
import { Tooltip } from 'antd';
import type { QRCodeProps } from 'antd';


// @ts-ignore
import { CSVLink, CSVDownload } from 'react-csv';
const { Title, Paragraph } = Typography;



const { TextArea } = Input;
const defaultLogoUrl = 'https://storage.googleapis.com/frm-reporting/fund-dash-user/1725995121.5944862_W6WSackaMBjNW5GfGJ6NYi.png';
const defaultHeroUrl = 'https://storage.googleapis.com/frm-reporting/fund-dash-user/1725657145.5373192_njLgoSL9xrghZ6K5XzznUC.png';
interface ParsedData {
  email_address: string;
  name: string;
}
function doDownload(url: string, fileName: string) {
  const a = document.createElement('a');
  a.download = fileName;
  a.href = url;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
const downloadCanvasQRCode = () => {
  const canvas = document.getElementById('myqrcode')?.querySelector<HTMLCanvasElement>('canvas');
  if (canvas) {
    const url = canvas.toDataURL('image/png');
    doDownload(url, 'QRCode.png');
  }
};
const downloadCampaignCanvasQRCode = () => {
  const canvas = document.getElementById('mycampaignqrcode')?.querySelector<HTMLCanvasElement>('canvas');
  if (canvas) {
    const url = canvas.toDataURL('image/png');
    doDownload(url, 'QRCode.png');
  }
};
const EditCampaign: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [isViewInvitesModalVisible, setIsViewInvitesModalVisible] = useState(false);
  
  const showViewInvitesModal = () => setIsViewInvitesModalVisible(true);
  const handleViewInvitesModalClose = () => setIsViewInvitesModalVisible(false);

  const [isAccountssignupModalVisible, setIsAccountssignupModalVisible] = useState(false);
  
  const showAccountssignupModal = () => setIsAccountssignupModalVisible(true);
  const handleAccountssignupModalClose = () => setIsAccountssignupModalVisible(false);
  
  const { data: campaignsData, isLoading: isLoadingCampaigns } = useGetCampaignsQuery({skip:0,limit:1000});
  const { data: groupsData, isLoading: isLoadingGroups } = useGetGroupsQuery({ skip: 0, limit: 1000, is_rep_group:false });
  const { data: catalogsData, isLoading: isLoadingCatalogs } = useGetInventoryCatalogsQuery({ skip: 0, limit: 1000 });
  const [updateCampaign, { isLoading, isError, isSuccess }] = useEditCampaignMutation();
  const navigate = useNavigate();
  const [ endDate,setEndDate]= useState(dayjs(campaignsData?.find((item: Campaign) => item.id == id )?.end_date) as unknown as string ?? '')

  const { register, handleSubmit, control, formState: { errors }, setValue, watch } = useForm({
    defaultValues: {
      campaign_link: '',
      logo_image_url: defaultLogoUrl,
      hero_image_url: defaultHeroUrl,
      video_url: '',
      title: '',
      blurb: '',
      money_goal: 0,
      money_raised: 0,
      end_date: '',
      is_closed: false,
      donations_enabled: false,
      leader_board_enabled: false,
      recent_orders_enabled:false,
      group_id: null,
      group_name: '',
      catalog_id: null,
      catalog_name: '',
      ship_promo_enabled: false,
      pre_sale_id: 0,
    }
  });

  const logoImageUrl = watch('logo_image_url');
  const heroImageUrl = watch('hero_image_url');
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [baseUrl, setBaseUrl] = useState(process.env.REACT_APP_BASE_URL_FE);
  const [isDrawerCatalogVisible, setIsDrawerCatalogVisible] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [selectedCatalog, setSelectedCatalog] = useState<Catalog | null>(null);
const [inviteStudents,{isLoading:isuploadinngcsv}]=useInviteStudentsMutation()
const [useDefaultLogo, setUseDefaultLogo] = useState(false);
const [useDefaultHero, setUseDefaultHero] = useState(false);
  useEffect(() => {
    if (campaignsData) {
      const campaign = campaignsData.find((item: Campaign) => item.id == id );
      if (campaign) {
        setValue('campaign_link', campaign.campaign_link);
        setValue('logo_image_url', campaign.logo_image_url);
        setValue('hero_image_url', campaign.hero_image_url);
        setValue('video_url', campaign.video_url);
        setValue('title', campaign.title);
        setValue('blurb', campaign.blurb);
        setValue('money_goal', campaign.money_goal);
        setValue('money_raised', campaign.money_raised);
        setValue('ship_promo_enabled', campaign.ship_promo_enabled);
        setValue('is_closed', campaign.is_closed);
        setValue('end_date', dayjs(campaign?.end_date) as unknown as string);
        setValue('donations_enabled', campaign.donations_enabled);
        setValue('leader_board_enabled', campaign.leader_board_enabled);
        setValue('recent_orders_enabled', campaign.recent_orders_enabled);
        setSelectedGroup(campaign.group);
        setSelectedCatalog(campaign.catalog);
        setValue('group_name', campaign?.group.name || '');
        setValue('catalog_name', campaign?.catalog?.name || '');
        setValue('pre_sale_id', campaign.pre_sale_id);
       
      }
    }
  }, [campaignsData, id, setValue, groupsData]);
  useEffect(()=>{
    setBaseUrl(process.env.REACT_APP_BASE_URL_FE)
  },[process.env.REACT_APP_BASE_URL_FE])
  const onSubmit = async (data: any) => {
   // data.end_date = moment(data.end_date).utc().toISOString();
   console.log( moment(data.end_date).utc().toISOString())
    data.group_id = selectedGroup?.id ?? null;
    data.catalog_id = selectedCatalog?.id ?? null;
    data.campaign_link = data.campaign_link;
    if (useDefaultLogo) {
      data.logo_image_url = defaultLogoUrl;
    }
    if (useDefaultHero) {
      data.hero_image_url = defaultHeroUrl;
    }
    try {
      await updateCampaign({ id: parseInt(id || '0'), ...data }).unwrap();
      console.log('Campaign updated successfully:', data);
      navigate(-1);
    } catch (error) {
          // @ts-ignore
          message.error(error?.data?.detail ||'Failed to update campaign.');
    }
  };
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992);

  useEffect(() => {
  

    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 992);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLogoImageUpload = (imageUrl: string) => {
    setValue('logo_image_url', imageUrl, { shouldValidate: true });
    setUseDefaultLogo(false);
  };

  const handleHeroImageUpload = (imageUrl: string) => {
    setValue('hero_image_url', imageUrl, { shouldValidate: true });
    setUseDefaultHero(false);  
  };

  const handleDrawerOpen = () => {
    setIsDrawerVisible(true);
  };

  const handleDrawerCatalogOpen = () => {
    setIsDrawerCatalogVisible(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };

  const handleDrawerCatalogClose = () => {
    setIsDrawerCatalogVisible(false);
  };

  const handleGroupSelect = (group: Group) => {
    setSelectedGroup(group);
    setValue('group_name', group.name);
    setIsDrawerVisible(false);
  };

  const handleCatalogSelect = (catalog: Catalog) => {
    setSelectedCatalog(catalog);
    setValue('catalog_name', catalog.name);
    setIsDrawerCatalogVisible(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${baseUrl}signup/${campaignsData?.find((item: Campaign) => item.id == id )?.group?.id}`);
    message.success('Invite link copied to clipboard');
  };
  const copyCampaignToClipboard = () => {
    // @ts-ignore
    navigator.clipboard.writeText(`${baseUrl}raise/${campaignsData.find((item: Campaign) => item.id == id )?.campaign_link}`);
    message.success('Invite link copied to clipboard');
  };
  
  const handleDataParsed = async (data: ParsedData[]) => {
    console.log('Parsed Data:', data);
    await inviteStudents({campaignId:id ??"0" ,data})
    message.success('CSV file parsed successfully!');
    // Do something with the data, e.g., send it to a server
  };
  
  function setRenderType(arg0: string | undefined): void {
    throw new Error('Function not implemented.');
  }

  return (
    <div className="edit-campaign-container">
      
      <Title level={2}>Edit Campaign</Title>
      <Form onFinish={handleSubmit(onSubmit)} layout="vertical" className="edit-campaign-form">
      <Form.Item
  label="Campaign Seller Invite Link"
 
 
>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' ,width:'700px'}}>
    <div style={{ display: 'flex', alignItems: 'center', width:"500px"}}>
      <Input
        value={`${baseUrl}signup/${campaignsData?.find((item: Campaign) => item.id == id )?.group?.id}`}
        readOnly
        addonAfter={<Button icon={<CopyOutlined />} onClick={copyToClipboard}>Copy</Button>}
      />
    </div>
    <div id="myqrcode" style={{ display: 'flex', alignItems: 'center', marginLeft: '16px'}}>
      <QRCode
        value={`${baseUrl}signup/${campaignsData?.find((item: Campaign) => item.id == id )?.group?.id}?ref=qr`}
        size={60}
        style={{ marginRight: '8px' }}
      />
      <Button icon={<DownloadOutlined />} onClick={downloadCanvasQRCode}>Download</Button>
    </div>
  </div>
</Form.Item>

        <Form.Item
          label="Campaign Store Link"
          validateStatus={errors.campaign_link ? 'error' : ''}
          help={errors.campaign_link ? errors.campaign_link.message : ''}
        >
          <Controller
            name="campaign_link"
            control={control}
            rules={{
              required: 'Campaign Store Link is required',
              pattern: {
                value: /^[a-zA-Z0-9-_]+$/,
                message: 'Only URL-friendly characters (letters, numbers, hyphens, and underscores) are allowed'
              }
            }}
            
            render={({ field }) => (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' ,width:'800px'}}>
                 <div style={{ display: 'flex', alignItems: 'center', width:"800px"}}>
              <Input
                addonBefore={`${baseUrl}raise/`}
                {...field}
                placeholder="Campaign Store Link"
                addonAfter={<Button icon={<CopyOutlined />} onClick={copyCampaignToClipboard}>Copy</Button>}
                
              />
              </div>
              <div id="mycampaignqrcode" style={{ display: 'flex', alignItems: 'center', marginLeft: '16px' }}>
      <QRCode
        value={`${baseUrl}raise/${campaignsData?.find((item: Campaign) => item.id == id )?.campaign_link}?ref=qr`}
        size={60}
        style={{ marginRight: '8px' }}
      />
      <Button icon={<DownloadOutlined />} onClick={downloadCampaignCanvasQRCode}>Download</Button>
    </div>
    </div>

              

            )}
          />
        </Form.Item>

        <Form.Item
          label="Logo Image URL"
          validateStatus={errors.logo_image_url ? 'error' : ''}
          help={errors.logo_image_url ? errors.logo_image_url.message : ''}
        >
          <Checkbox
            checked={useDefaultLogo}
            onChange={(e) => {
              setUseDefaultLogo(e.target.checked);
              
            }}
          >
            Use Default Logo
          </Checkbox>
          {(
            <Controller
              name="logo_image_url"
              control={control}
              rules={{ required: 'Logo image is required' }}
              render={({ field }) => (
                <>
                  {(useDefaultLogo?defaultLogoUrl:logoImageUrl) && (
                    <img src={useDefaultLogo?defaultLogoUrl:logoImageUrl} alt="Logo" style={{ width: '256px', height: '105.6px' }} />
                  )}
               {!useDefaultLogo &&    <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ImageUploader onUpload={(imageUrl) => handleLogoImageUpload(imageUrl)} width={640} height={264} />
                    <Tooltip title="For best results, please upload a 640 × 264 png or jpg">
                      <QuestionCircleOutlined style={{ marginLeft: 8, marginTop: 20, color: '#1890ff' }} />
                    </Tooltip>
                  </div>}
                </>
              )}
            />
          )}
          

        </Form.Item>

        <Form.Item
          label="Hero Image URL"
          validateStatus={errors.hero_image_url ? 'error' : ''}
          help={errors.hero_image_url ? errors.hero_image_url.message : ''}
        >
          <Checkbox
            checked={useDefaultHero}
            onChange={(e) => {
              setUseDefaultHero(e.target.checked);
              
            }}
          >
            Use Default Hero
          </Checkbox>
          { (
            <Controller
              name="hero_image_url"
              control={control}
              rules={{ required: 'Hero image is required' }}
              render={({ field }) => (
                <>
                  {(useDefaultHero?defaultHeroUrl:heroImageUrl) && (
                    <img src={useDefaultHero?defaultHeroUrl:heroImageUrl} alt="Hero" style={{ width: '320px', height: '180px' }} />
                  )}
                {!useDefaultHero &&  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ImageUploader onUpload={(imageUrl) => handleHeroImageUpload(imageUrl)} width={1600} height={900} />
                    <Tooltip title="For best results, please upload a 1600 × 900 png or jpg">
                      <QuestionCircleOutlined style={{ marginLeft: 8, marginTop: 20, color: '#1890ff' }} />
                    </Tooltip>
                  </div>}
                </>
              )}
            />
          )}
        </Form.Item>

        <Form.Item
          label="Video URL"
          validateStatus={errors.video_url ? 'error' : ''}
          help={errors.video_url ? errors.video_url.message : ''}
        >
          <Controller
            name="video_url"
            control={control}
            render={({ field }) => (
              <Input type="text" {...field} />
            )}
          />
        </Form.Item>

        <Form.Item
          label="Title"
          validateStatus={errors.title ? 'error' : ''}
          help={errors.title ? errors.title.message : ''}
        >
          <Controller
            name="title"
            control={control}
            rules={{ required: 'Title is required' }}
            render={({ field }) => (
              <Input type="text" {...field} />
            )}
          />
        </Form.Item>

        <Form.Item
          label="Blurb"
          validateStatus={errors.blurb ? 'error' : ''}
          help={errors.blurb ? errors.blurb.message : ''}
        >
          <Controller
            name="blurb"
            control={control}
            rules={{ required: 'Blurb is required' }}
            render={({ field }) => (
              <TextArea {...field} rows={4} />
            )}
          />
        </Form.Item>

        <Form.Item
          label="Money Goal"
          validateStatus={errors.money_goal ? 'error' : ''}
          help={errors.money_goal ? errors.money_goal.message : ''}
        >
          <Controller
            name="money_goal"
            control={control}
            rules={{ required: 'Money goal is required' }}
            render={({ field }) => (
              <CurrencyInput
              className='ant-input css-dev-only-do-not-override-zg0ahe ant-input-outlined'
               placeholder="Please enter Goal"
               defaultValue={((campaignsData?.find((item: Campaign) => item.id == id )?.money_goal)??0)/100}
               value={field.value/100}
               decimalsLimit={2}
               onValueChange={(value) => {
                 const centsValue = value ? parseFloat(value) * 100 : 0;
                 field.onChange( centsValue );
               }}
               prefix="$"
             />
            )}
          />
        </Form.Item>

        <Form.Item
          label="Money Raised"
          validateStatus={errors.money_raised ? 'error' : ''}
          help={errors.money_raised ? errors.money_raised.message : ''}
        >
          <Controller
            name="money_raised"
            control={control}
           
            render={({ field }) => (
              <Input type="text" 
              disabled
              value={(field.value as number / 100).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
              onChange={(e) => {
                // Remove non-numeric characters and convert to cents
                const centsValue = parseInt(e.target.value.replace(/[^\d]/g, ''), 10);
                field.onChange(centsValue);
              }}  />
            )}
          />
        </Form.Item>

        <Form.Item
          label="End Date and Time"
          validateStatus={errors.end_date ? 'error' : ''}
          help={errors.end_date ? errors.end_date.message : ''}
        >
          <Controller
            name="end_date"
            control={control}
            rules={{ required: 'End date and time is required' }}
            render={({ field }) => (
              <DatePicker 
                {...field} 
                style={{ width: '100%' }} 
                showTime 
                format="YYYY-MM-DD HH:mm:ss"
                placeholder="Select End Date and Time"
              />
            )}
          />
        </Form.Item>

        <Form.Item
          label="Is Closed"
          validateStatus={errors.is_closed ? 'error' : ''}
          help={errors.is_closed ? errors.is_closed.message : ''}
        >
          <Controller
            name="is_closed"
            control={control}
            render={({ field }) => (
              <Switch checked={field.value} onChange={field.onChange} />
            )}
          />
        </Form.Item>
        <Form.Item
          label="Ship Promo Enabled"
          validateStatus={errors.is_closed ? 'error' : ''}
          help={errors.ship_promo_enabled ? errors.ship_promo_enabled.message : ''}
        >
          <Controller
            name="ship_promo_enabled"
            control={control}
            render={({ field }) => (
              <Switch checked={field.value} onChange={field.onChange} />
            )}
          />
        </Form.Item>
        <Form.Item
          label="Donations Enabled"
          validateStatus={errors.donations_enabled ? 'error' : ''}
          help={errors.donations_enabled ? errors.donations_enabled.message : ''}
        >
          <Controller
            name="donations_enabled"
            control={control}
            render={({ field }) => (
              <Switch checked={field.value} onChange={field.onChange} />
            )}
          />
        </Form.Item>

        <Form.Item
          label="Leader Board enabled"
          validateStatus={errors.leader_board_enabled ? 'error' : ''}
          help={errors.leader_board_enabled ? errors.leader_board_enabled.message : ''}
        >
          <Controller
            name="leader_board_enabled"
            control={control}
            render={({ field }) => (
              <Switch checked={field.value} onChange={field.onChange} />
            )}
          />
        </Form.Item>

        <Form.Item
          label="Pre-Sale-ID"
          validateStatus={errors.pre_sale_id ? 'error' : ''}
          help={errors.pre_sale_id ? errors.pre_sale_id.message : ''}
        >
          <Controller
            name="pre_sale_id"
            control={control}
            rules={{ required: 'pre_sale_id is required' }}
            render={({ field }) => (
              <Input type="text" {...field} />
            )}
          />
        </Form.Item>

        <Form.Item
          label="Recent Orders Enabled"
          validateStatus={errors.recent_orders_enabled ? 'error' : ''}
          help={errors.recent_orders_enabled ? errors.recent_orders_enabled.message : ''}
        >
          <Controller
            name="recent_orders_enabled"
            control={control}
            render={({ field }) => (
              <Switch checked={field.value} onChange={field.onChange} />
            )}
          />
        </Form.Item>

        <Form.Item
          label="Group"
          validateStatus={errors.group_name ? 'error' : ''}
          help={errors.group_name ? errors.group_name.message : ''}
        >
          <Controller
            name="group_name"
            control={control}
            rules={{ required: 'Group is required' }}
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  readOnly
                  onClick={handleDrawerOpen}
                  placeholder="Select a group"
                />
                <Button onClick={handleDrawerOpen}>Select Group</Button>
              </>
            )}
          />
        </Form.Item>
        <Form.Item
          label="Catalog"
          validateStatus={errors.catalog_name ? 'error' : ''}
          help={errors.catalog_name ? errors.catalog_name.message : ''}
        >
          <Controller
            name="catalog_name"
            control={control}
            rules={{ required: 'Catalog is required' }}
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  readOnly
                  onClick={handleDrawerCatalogOpen}
                  placeholder="Select a catalog"
                />
                <Button onClick={handleDrawerCatalogOpen}>Select Catalog</Button>
              </>
            )}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>Update Campaign</Button>
        </Form.Item>
        

        {isError && <p>Failed to update campaign. Please try again.</p>}
        {isSuccess && <p>Campaign updated successfully!</p>}
      </Form>

      <Drawer
        title="Select Group"
        width={400}
        onClose={handleDrawerClose}
        visible={isDrawerVisible}
      >
        <List
          dataSource={groupsData ?? []}
          renderItem={(group: Group) => (
            <List.Item key={group.id}>
              <Radio
                checked={selectedGroup?.id == group.id}
                onChange={() => handleGroupSelect(group)}
              >
                {group.name}
              </Radio>
            </List.Item>
          )}
        />
      </Drawer>
      <Drawer
        title="Select Catalog"
        width={400}
        onClose={handleDrawerCatalogClose}
        visible={isDrawerCatalogVisible}
      >
        <List
          dataSource={catalogsData ?? []}
          renderItem={(catalog: Catalog) => (
            <List.Item key={catalog.id}>
              <Radio
                checked={selectedCatalog?.id == catalog.id}
                onChange={() => handleCatalogSelect(catalog)}
              >
                {catalog.name}
              </Radio>
            </List.Item>
          )}
        />
      </Drawer>
      <Title level={2}>Invite students</Title>
       <CSVUploader onDataParsed={handleDataParsed} loading={isuploadinngcsv}/>
       <Button type="primary"  onClick={showViewInvitesModal} style={{ marginLeft: '8px' }}>
        View invites
      </Button>
      <Button type="primary"  onClick={showAccountssignupModal} style={{ marginLeft: '8px' }}>
        Account Signup Details
      </Button>
       <Paragraph>
        Not sure what to include?
        <CSVLink 
         data ={`name,email
John Doe,jdoe@example.com`}
         >
          {''}Download our sample CSV
         </CSVLink>
        
         
         ;



       </Paragraph>
       {isViewInvitesModalVisible&&<ViewInvitesModal visible={isViewInvitesModalVisible} onClose={handleViewInvitesModalClose} campaignId={id!} />}
       {isAccountssignupModalVisible&&<AccountsignupModal isMobile={!isDesktop} visible={isAccountssignupModalVisible} onClose={handleAccountssignupModalClose} campaignId={id!} />}

    </div>
    
  );
};

export default EditCampaign;
