import React, { useEffect } from 'react';
import { Table, Pagination, Spin, message } from 'antd';
import { useGetUsersQuery } from '../services/admin/inventoryApi';
import usePagination from '../hooks/usePagination';
import { User } from '../types.ts'; // Ensure correct import path

const columns = [
  {
    title: 'Name',
    dataIndex: 'full_name',
    key: 'full_name',
    sorter: (a: User, b: User) => a.full_name.localeCompare(b.full_name), // Sort alphabetically by name
  },
  {
    title: 'Scope',
    dataIndex: 'scope',
    key: 'scope',
    sorter: (a: User, b: User) => a.scope.localeCompare(b.scope),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: (a: User, b: User) => a.email.localeCompare(b.email),
  },
  {
    title: 'Is Active',
    dataIndex: 'is_active',
    key: 'is_active',
    sorter: (a: User, b: User) => Number(a.is_active) - Number(b.is_active),
    render: (text: boolean) => (text ? 'Yes' : 'No'),
  },
  {
    title: 'Group Name',
    dataIndex: 'groups',
    key: 'groups',
    sorter: (a: User, b: User) => {
      const groupA = a.groups?.map((group) => group.name).join(', ') || '';
      const groupB = b.groups?.map((group) => group.name).join(', ') || '';
      return groupA.localeCompare(groupB);
    },
    render: (groups: { name: string }[]) =>
      groups?.map((group) => group.name).join(', ') || '-',
  },
  
  // Add more columns as necessary
];

interface UsersTableProps {
  handleRowClick?: (record: User) => void;
}

const UsersTable: React.FC<UsersTableProps> = ({  handleRowClick }) => {
  const { currentPage, pageSize, onPageChange } = usePagination();

  const { data: usersData, error: usersError, isLoading: usersLoading } = useGetUsersQuery({
    skip: (currentPage - 1) * pageSize,
    limit: pageSize,
  });

  useEffect(() => {
    if (usersError) {
      message.error('Failed to load users');
    }
  }, [usersError]);

  return (
    <>
      {usersLoading ? (
        <Spin tip="Loading..." />
      ) : (
        <>
          <Table
            columns={columns}
            loading={usersLoading}
            dataSource={usersData?.data || []}
            pagination={false}
            rowKey="id"
            onRow={(record) => ({
              onClick: () => handleRowClick&&handleRowClick(record),
            })}
             rowClassName="clickable-row"
          />

          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={usersData?.count} // Assuming `total` should be set dynamically based on `usersData`
            onChange={onPageChange}
            showSizeChanger={false}
          />
        </>
      )}
    </>
  );
};

export default UsersTable;
