import React from 'react';
import { useParams } from 'react-router-dom';
import AddToCartButton from './AddToCartButton';
import '../styles/ProductDetails.scss';
import { useGetCampaignForUserQuery } from '../services/campaign';
import { formatPrice } from '../utils';

const ProductDetails: React.FC = () => {
  const { id, campaign_id, user_id } = useParams<{ id: string, campaign_id: string; user_id: string }>();
  const { data, error, isLoading } = useGetCampaignForUserQuery(campaign_id || '0', {
    skip: !campaign_id,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading product</div>;
  }

  const product = data?.products.find(p => p.id === id);

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="product-details">
      <img src={product.primary_image_url} alt={product.title} className="product-image" />
      <div className="product-details-content">
        <h1>{product.title}</h1>
        <div className="price">
          <p className="product-price">${formatPrice(product.cost)}</p>
          <p className="product-price">{product.fulfilment_type === "STH"? 'Ship to Home':'Ship to School'}</p>
        </div>
        <AddToCartButton product={product} />
        <p>{product.blurb}</p>
      </div>
    </div>
  );
};

export default ProductDetails;
