import React, { useState, useEffect } from 'react';
import { FaHome, FaSchool, FaDonate } from 'react-icons/fa';
import '../styles/ShippingOptions.scss';
import { useParams } from 'react-router-dom';
import { useGetCampaignForUserQuery } from '../services/campaign';

const ShippingOptions: React.FC<{ onOptionSelect: (option: string) => void,selectedOption:string }> = ({ onOptionSelect,selectedOption }) => {
  const { campaign_id } = useParams<{ campaign_id: string }>();
  const { data, error, isLoading, isError } = useGetCampaignForUserQuery(campaign_id || '0', {
    skip: !campaign_id,
  });


  const [showHomeOption, setShowHomeOption] = useState<boolean>(false);
  const [showSchoolOption, setShowSchoolOption] = useState<boolean>(false);

  useEffect(() => {
    if (data?.products) {
      setShowHomeOption(data.products.some(product => product.fulfilment_type === 'STH'));
      setShowSchoolOption(data.products.some(product => product.fulfilment_type === 'STS'));
      console.log(data.products.some(product => product.fulfilment_type === 'STH'),data.products.some(product => product.fulfilment_type === 'STH'))
      if(data.products.some(product => product.fulfilment_type === 'STH')){
        onOptionSelect('home')
      }else if(data.products.some(product => product.fulfilment_type === 'STS'))(
        onOptionSelect('school')
      )
    }
  }, [data]);

  const handleOptionSelect = (option: string) => {
    onOptionSelect(option);
    onOptionSelect(option);
  };

  if (isError) return <div>Error loading shipping options</div>;
  if(data?.is_closed) return <></>
  if (isLoading) return <div></div>;

  return (
    <div className="shipping-options-container">
      {showHomeOption && (
        <div
          className={`option ${selectedOption === 'home' ? 'option-active' : ''}`}
          onClick={() => handleOptionSelect('home')}
        >
          <FaHome className='icon' />Ship to home
        </div>
      )}
      {showSchoolOption && (
        <div
          className={`option ${selectedOption === 'school' ? 'option-active' : ''}`}
          onClick={() => handleOptionSelect('school')}
        >
          <FaSchool className='icon' />Ship to school
        </div>
      )}
    {data?.donations_enabled&&  <div
        className={`option-donation ${selectedOption === 'donate' ? 'option-active' : ''}`}
        onClick={() => handleOptionSelect('donate')}
      >
        <FaDonate className='icon' />Donate
      </div>}
    </div>
  );
};

export default ShippingOptions;
