import React, { useEffect, useState } from 'react';
import '../styles/AddUser.scss';
import ImageUploader from './ImageUploader';
import { Form, Input, Button, Typography, Switch, Drawer, List, Radio, Checkbox, Select } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import { useAddUserMutation, useGetGroupsQuery } from '../services/admin/inventoryApi';
import { useNavigate } from 'react-router-dom';
import CurrencyInput from 'react-currency-input-field';
import { Group } from '../types.ts';

const { TextArea } = Input;
const { Title } = Typography;
const { Option } = Select;

const AddUser: React.FC = () => {
  const { register, handleSubmit, control, formState: { errors }, setValue, watch } = useForm({
    defaultValues: {
      email: '',
      is_active: false,
      //is_superuser: false,
      full_name: '',
      scope: 'student',
      in_leaderboard:false,
      primary_image_url: '',
      raise_goal: 0,
      blurb: '',
      password: '',
      groups: []
    }
  });

  const primaryImageUrl = watch('primary_image_url');
  const { data: groupsData, isLoading: isLoadingGroups } = useGetGroupsQuery({ skip: 0, limit: 1000, 
      //@ts-ignore
    is_rep_group:null });
  const [addUser, { isLoading, isError, isSuccess }] = useAddUserMutation();
  const [showPassword, setShowPassword] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
  const [hasMounted, setHasMounted] = useState(false);
  const [groupError, setGroupError] = useState<string | null>(null);  // New state for group validation error
  const navigate = useNavigate();

  const onSubmit = async (data: any) => {
  
    if (selectedGroups.length === 0) {
      setGroupError("At least one group must be selected.");
      return;
    }
    data.group_ids = selectedGroups.map(group => group.id);
    setGroupError(null);
    
    try {
      await addUser(data).unwrap();
      console.log('User added successfully:', data);
      navigate(-1);
    } catch (error) {
      console.error('Failed to add user:', error);
    }
  };

  const handleImageUpload = (imageUrl: string) => {
    setValue('primary_image_url', imageUrl, { shouldValidate: true });
  };

  const handleDrawerOpen = () => {
    setIsDrawerVisible(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };

  const handleGroupSelect = (group: Group) => {
    setSelectedGroups(prevGroups => {
      if (prevGroups.some(g => g.id === group.id)) {
        return prevGroups.filter(g => g.id !== group.id);
      }
      return [...prevGroups, group];
    });
    if (selectedGroups.length > 0) {
      setGroupError(null);
    } else if (selectedGroups.length === 0) {
      setGroupError("At least one group must be selected.");
    }
  };
  useEffect(()=>{
    if (selectedGroups.length > 0) {
      setGroupError(null);
    }
  },[selectedGroups])


  return (
    <div className="add-user-container">
      <Title level={2}>Add User</Title>
      <Form onFinish={handleSubmit(onSubmit)} layout="vertical" className="add-user-form">
        <Form.Item
          label="Email"
          validateStatus={errors.email ? 'error' : ''}
          help={errors.email ? errors.email.message : ''}
        >
          <Controller
            name="email"
            control={control}
            rules={{ required: 'Email is required' }}
            render={({ field }) => (
              <Input type="email" {...field} />
            )}
          />
        </Form.Item>

        <Form.Item
          label="Full Name"
          validateStatus={errors.full_name ? 'error' : ''}
          help={errors.full_name ? errors.full_name.message : ''}
        >
          <Controller
            name="full_name"
            control={control}
            rules={{ required: 'Full name is required' }}
            render={({ field }) => (
              <Input type="text" {...field} />
            )}
          />
        </Form.Item>

        <Form.Item
          label="Scope"
          validateStatus={errors.scope ? 'error' : ''}
          help={errors.scope ? errors.scope.message : ''}
        >
          <Controller
            name="scope"
            control={control}
            rules={{ required: "Scope is required" }}
            render={({ field }) => (
              <Select {...field} placeholder="Select Scope">
                <Option value="student">Student</Option>
                
                <Option value="rep">Rep</Option>
              </Select>
            )}
          />
        </Form.Item>
        <div className="form-item">
  <label>Show Name in Public Leaderboard</label>
  <Controller
    name="in_leaderboard"
    control={control}
    render={({ field }) => (
      <Switch checked={field.value} onChange={field.onChange} />
    )}
  />
</div>



        <Form.Item
          label="Primary Image URL"
          validateStatus={errors.primary_image_url ? 'error' : ''}
          help={errors.primary_image_url ? errors.primary_image_url.message : ''}
        >
          <Controller
            name="primary_image_url"
            control={control}
            render={({ field }) => (
              <>
                {primaryImageUrl && (
                  <img src={primaryImageUrl} alt="Primary" style={{ width: '100px', height: '100px' }} />
                )}
                <ImageUploader onUpload={(imageUrl) => handleImageUpload(imageUrl)}
                width={512}
                height={512}
                />
              </>
            )}
          />
        </Form.Item>

        <Form.Item
          label="Raise Goal"
          validateStatus={errors.raise_goal ? 'error' : ''}
          help={errors.raise_goal ? errors.raise_goal.message : ''}
        >
          <Controller
            name="raise_goal"
            control={control}
            rules={{ required: 'Raise goal is required' }}
            render={({ field }) => (
              <CurrencyInput
              className='ant-input css-dev-only-do-not-override-zg0ahe ant-input-outlined'
               placeholder="Please enter Money Goal"
               defaultValue={field.value}
               decimalsLimit={2}
               onValueChange={(value) => {
                 const centsValue = value ? parseFloat(value) * 100 : 0;
                 field.onChange( centsValue );
               }}
               prefix="$"
             />
            )}
          />
        </Form.Item>

        <Form.Item
          label="Blurb"
          validateStatus={errors.blurb ? 'error' : ''}
          help={errors.blurb ? errors.blurb.message : ''}
        >
          <Controller
            name="blurb"
            control={control}
            
            render={({ field }) => (
              <TextArea {...field} rows={4} />
            )}
          />
        </Form.Item>

        <Form.Item
          label="Password"
          validateStatus={errors.password ? 'error' : ''}
          help={errors.password ? errors.password.message : ''}
        >
          <Controller
            name="password"
            control={control}
            rules={{ required: 'Password is required' }}
            render={({ field }) => (
              <Input
                type={showPassword ? "text" : "password"}
                {...field}
                addonAfter={
                  <Button
                    onClick={() => setShowPassword(!showPassword)}
                    type="link"
                  >
                    {showPassword ? "Hide" : "Show"}
                  </Button>
                }
              />
            )}
          />
        </Form.Item>

        <Form.Item
          label="Is Active"
          validateStatus={errors.is_active ? 'error' : ''}
          help={errors.is_active ? errors.is_active.message : ''}
        >
          <Controller
            name="is_active"
            control={control}
            render={({ field }) => (
              <Switch checked={field.value} onChange={field.onChange} />
            )}
          />

        </Form.Item>

      

        <Form.Item
          label="Groups"
          
        >
          <Controller
            name="groups"
            control={control}
           
            render={({ field }) => (
              <>
                <Button onClick={handleDrawerOpen}>Select Groups</Button>
                <List
                  dataSource={selectedGroups}
                  renderItem={group => (
                    <List.Item key={group.id}>
                      {group.name}
                    </List.Item>
                  )}
                />
<p style={{color:"#ff4d4f"}}>{  groupError}</p>
              </>
            )}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>Add User</Button>
        </Form.Item>

        {isError && <p>Failed to add user. Please try again.</p>}
        {isSuccess && <p>User added successfully!</p>}
      </Form>

      <Drawer
        title="Select Groups"
        width={400}
        onClose={handleDrawerClose}
        visible={isDrawerVisible}
      >
        <List
          dataSource={groupsData ?? []}
          renderItem={(group: Group) => (
            <List.Item key={group.id}>
              <Checkbox
                checked={selectedGroups.some(g => g.id === group.id)}
                onChange={() => handleGroupSelect(group)}
              >
                {group.name}
              </Checkbox>
            </List.Item>
          )}
        />
      </Drawer>
    </div>
  );
};

export default AddUser;