import React, { ComponentType, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../store';

interface PrivateRouteProps {
  component?: ComponentType<any>;
  children?: ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, children }) => {
  const token = useSelector((state: RootState) => state.adminUser.token);

  if (!token) {
    return <Navigate to="/signin" />;
  }

  if (Component) {
    return <Component />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
