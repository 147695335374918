// src/components/Reports.tsx

import React, { useEffect } from 'react';
import { Table, Spin, message, Button, Pagination } from 'antd';
import Papa from 'papaparse';
import { useGetCampaignsQuery, useDownloadReportMutation } from '../services/admin/inventoryApi';
import usePagination from '../hooks/usePagination';
import { Campaign } from '../types.ts';
import { DownloadOutlined } from '@ant-design/icons';





const ReportsTable: React.FC<{isMobile: boolean}>= ({isMobile}) => {
  const { currentPage, pageSize, onPageChange } = usePagination();

  const { data, error, isLoading } = useGetCampaignsQuery({
    skip: (currentPage - 1) * pageSize,
    limit: pageSize,
  });
  const [downloadReport,{data:csv}] = useDownloadReportMutation();

  useEffect(() => {
    if (error) {
      message.error('Failed to load campaigns');
    }
  }, [error]);
  const handleDownload = async (campaignId: string, reportType: 'based_order' | 'based_user' | 'based_product') => {
    try {
      // Call the API and expect a response in CSV format
      const response = await downloadReport({ campaignId, reportType }).unwrap();
  
      // Check if response is a Blob or string
      // @ts-ignore
      if (response instanceof Blob) {
        const url = URL.createObjectURL(response);
  
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `report_${campaignId}_${reportType}.csv`);
        document.body.appendChild(link);
        link.click();
  
        link.remove();
        URL.revokeObjectURL(url);
      } else if (typeof response === 'string') {
        // If response is a string, handle it as CSV content
        const blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
  
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `report_${campaignId}_${reportType}.csv`);
        document.body.appendChild(link);
        link.click();
  
        link.remove();
        URL.revokeObjectURL(url);
      } else {
        message.error('Unexpected response format');
      }
    } catch (err) {
      console.error('Download Error:', err);
      message.error('Failed to download report');
    }
  };

  const columns = [
    {
      title: 'Pre Sale ID',
      dataIndex: 'pre_sale_id',
      key: 'pre_sale_id',
    sorter: (a: Campaign, b: Campaign) => a.pre_sale_id - b.pre_sale_id,
    },
    {
      title: 'Campaign Name',
      dataIndex: 'title',
      key: 'title',
      sorter: (a: any, b: any) => a.title.localeCompare(b.title),
    },
    {
      title: 'Order Report',
      dataIndex: 'orderReport',
      key: 'orderReport',
      render: (text: string, record: Campaign) => (
        <Button onClick={() => handleDownload(record.id, "based_order")} icon={<DownloadOutlined />}> {!isMobile && 'Download Order Report'}</Button>
      ),
    },
    {
      title: 'User Report',
      dataIndex: 'userReport',
      key: 'userReport',
      render: (text: string, record: Campaign) => (
        <Button onClick={() => handleDownload(record.id, "based_user")} icon={<DownloadOutlined />}>{!isMobile && 'Download User Report'}</Button>
      ),
    },
    {
      title: 'Product Report',
      dataIndex: 'productReport',
      key: 'productReport',
      render: (text: string, record: Campaign) => (
        <Button onClick={() => handleDownload(record.id, "based_product")} icon={<DownloadOutlined />}>  {!isMobile && 'Download Product Report'}</Button>
      ),
    },
  ];
  return (
    <>
      {isLoading ? (
        <Spin tip="Loading..." />
      ) : (
        <>
          <Table
            columns={columns}
            dataSource={data || []}
            pagination={false}
            rowKey="id"
          />
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={0}
            onChange={onPageChange}
            showSizeChanger={false}
          />
        </>
      )}
    </>
  );
};

export default ReportsTable;
