// src/components/ViewInvitesModal.tsx

import React from 'react';
import { Modal, Table } from 'antd';
import { useGetInviteCampaignsQuery } from '../services/admin/inventoryApi';
import { convertUTCDateToLocalDate } from '../utils';

interface ViewInvitesModalProps {
  visible: boolean;
  onClose: () => void;
  campaignId: string;
}

const ViewInvitesModal: React.FC<ViewInvitesModalProps> = ({ visible, onClose, campaignId }) => {
  const { data: invitesData, isLoading: isLoadingInvites } = useGetInviteCampaignsQuery({ campaignId, skip: 0, limit: 1000 });

  const columns = [
    { title: 'Email', dataIndex: 'email_address', key: 'email_address' },
    { title: 'Added', dataIndex: 'created_at', key: 'created_at', render: (text: string) => {
      const targetDate = convertUTCDateToLocalDate(new Date(text) as Date);
    
      return isNaN(targetDate.getTime()) ? 'Invalid Date' : targetDate.toLocaleString();
    }, },
    { title: 'Sent', dataIndex: 'is_sent_email_1', key: 'is_sent_email_1', render: (text: boolean) => (text ? 'Yes' : 'No') },
  ];

  return (
    <Modal
      title="Invites"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <Table
        dataSource={invitesData?.data || []}
        loading={isLoadingInvites}
        rowKey="email_address"
        columns={columns}
        pagination={{ pageSize: 10 }}
      />
    </Modal>
  );
};

export default ViewInvitesModal;
