import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Loader = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      {/* Logo and title */}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <Skeleton circle={true} height={50} width={50} />
        <div style={{ marginLeft: '10px' }}>
          <Skeleton height={30} width={150} />
        </div>
      </div>
      {/* Main content */}
      <Skeleton height={30} width={200} style={{ marginBottom: '10px' }} />
      <Skeleton height={20} width={`90%`} style={{ marginBottom: '10px' }} />
      <Skeleton height={20} width={`80%`} style={{ marginBottom: '10px' }} />
      <Skeleton height={20} width={`95%`} style={{ marginBottom: '10px' }} />
      <Skeleton height={20} width={`85%`} style={{ marginBottom: '30px' }} />
      {/* Fundraising image */}
      <Skeleton height={300} width={`100%`} />
    </div>
  );
};

export default Loader;
