import React from "react";
import { useNavigate } from 'react-router-dom';
import { Button, Form, Grid, Input, Typography, theme, Spin } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { useForgotPasswordMutation } from '../services/admin/adminAuth';

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title } = Typography;

const ForgotPassword: React.FC = () => {
  const { token } = useToken();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const [forgotPassword, { isLoading, isError, isSuccess }] = useForgotPasswordMutation();

  const handleSubmit = async (values: { email: string }) => {
    try {
      await forgotPassword(values.email).unwrap();
    } catch (error) {
      console.error('Failed to send password recovery email:', error);
    }
  };

  const styles: { [key: string]: React.CSSProperties } = {
    container: {
      margin: "0 auto",
      padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px"
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%"
    },
    header: {
      marginBottom: token.marginXL
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "auto",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px"
    },
    text: {
      color: token.colorTextSecondary
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3
    }
  };

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        <div style={styles.header}>
          <Title style={styles.title}>Forgot Password</Title>
          <Text style={styles.text}>
            We are here to help.
          </Text>
        </div>
        <Form
          name="forgot_password"
          initialValues={{ remember: true }}
          layout="vertical"
          requiredMark="optional"
          onFinish={handleSubmit}
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please input your Email!",
              },
            ]}
          >
            <Input
              prefix={<MailOutlined />}
              placeholder="Email"
            />
          </Form.Item>
          {isError && (
            <Form.Item>
              <Text type="danger">Failed to send password recovery email. Please try again.</Text>
            </Form.Item>
          )}
          {isSuccess && (
            <Form.Item>
              <Text type="success">Password recovery email sent. Please check your inbox.</Text>
            </Form.Item>
          )}
          <Form.Item style={{ marginBottom: "0px" }}>
            <Button block type="primary" htmlType="submit" disabled={isLoading}>
              {isLoading ? <Spin /> : 'Send Reset Password Link'}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </section>
  );
};

export default ForgotPassword;
