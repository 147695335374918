// File path: src/components/MessageForm.tsx

import React from 'react';
import { useForm, Controller, FieldError } from 'react-hook-form';
import { Button, Input, Form, message } from 'antd';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import { usePostMessageMutation } from '../services/campaign';

interface FormValues {
  name: string;
  blurb: string;
}

interface MessageFormProps {
  order_id: string | null;
  campaign_id: string | null;
  user_id: string | null;
}

const MessageForm: React.FC<MessageFormProps> = ({
  order_id,
  campaign_id,
  user_id,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>();

  const [postMessage, { isError: isErrorPostingMessage, isLoading: isLoadingPostingMessage, isSuccess }] = usePostMessageMutation();

  const getErrorMessage = (error: FieldError | undefined): string | undefined => {
    return error ? error.message : undefined;
  };
console.log(user_id=="undefined")
  const onSubmit = async (data: FormValues) => {
   const userId = (user_id === "undefined") ? null : user_id;
    try {
      const messageData = {
        ...data,
        order_id,
        campaign_id,
        message: data.blurb,
        user_id:userId,
      };
      await postMessage(messageData).unwrap();
      console.log('User added successfully:', data);
      message.success('Message sent successfully!');
    } catch (error) {
      console.error('Failed to add user:', error);
      //@ts-ignore
      message.error(error?.data?.detail || 'Failed to send message. Please try again.');
    }
  };
if(isSuccess)return<></>
  return (
    <>
      <Title level={2}>Share a Message</Title>
      <Paragraph>
        Your message will be publicly visible on the campaign page, thanks for your support!
      </Paragraph>
      <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
        <Form.Item
          label="Name"
          validateStatus={errors.name ? 'error' : ''}
          help={getErrorMessage(errors.name)}
        >
          <Controller
            name="name"
            control={control}
            rules={{ required: 'Name is required' }}
            render={({ field }) => (
              <Input {...field} placeholder="Enter your name" />
            )}
          />
        </Form.Item>

        <Form.Item
          label="Blurb"
          validateStatus={errors.blurb ? 'error' : ''}
          help={getErrorMessage(errors.blurb)}
        >
          <Controller
            name="blurb"
            control={control}
            rules={{ required: 'Blurb is required' }}
            render={({ field }) => (
              <Input.TextArea {...field} placeholder="Enter your message" />
            )}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoadingPostingMessage} style={{
            width:"40%",
            padding: '15px 20px',
            borderRadius:'10px',
         
          }}>
            Publish
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default MessageForm;
