import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import adminReducer from './slices/admin/adminUserSlice';
import activeTabReducer from './slices/admin/activeTabSlice';
import cartReducer from './slices/campaign/cartSlice';
import addressReducer from './slices/campaign/addressSlice';
import donationReducer from './slices/campaign/donationSlice';
import { adminAuthApi } from './services/admin/adminAuth';
import { inventoryApi } from './services/admin/inventoryApi';
import { campaignApi } from './services/campaign';
import { utilsApi } from './services/utils';

type TabKey = 'tab1' | 'tab2' | 'tab3' | 'tab4' | 'tab5' | 'tab6' | 'tab7' | 'tab8' | 'tab9'|'tab10'| 'tab11';

interface ActiveTabState {
  activeTab: TabKey;
}

const migrations = {
  //@ts-ignore
  1: (state: { history}) => {
    return {
      ...state,
      history: {
        ...state.history,
        newHistoryOfSomething: [],
      },
    };
  },
  2: (state: { activeTab: TabKey }) => {
    return {
      ...state,
      activeTab: state.activeTab === 'tab1' ? 'tab5' : state.activeTab,
    };
  },
};

const persistConfig = {
  //@ts-ignore
  migrate: createMigrate(migrations, { debug: false }),
  key: 'root',
  storage,
  whitelist: ['adminUser', 'cart', 'donation','activeTab'], // List of reducers you want to persist
};

const rootReducer = combineReducers({
  adminUser: adminReducer,
  activeTab: activeTabReducer,
  cart: cartReducer,
  address: addressReducer,
  donation: donationReducer,
  [campaignApi.reducerPath]: campaignApi.reducer,
  [adminAuthApi.reducerPath]: adminAuthApi.reducer,
  [inventoryApi.reducerPath]: inventoryApi.reducer,
  [utilsApi.reducerPath]: utilsApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(adminAuthApi.middleware, campaignApi.middleware, utilsApi.middleware,  inventoryApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

export default store;
