import React from 'react';

interface SvgIconProps {
  width?: number;
  height?: number;
  fill?: string;
  className?: string;
}

const SvgIcon: React.FC<SvgIconProps> = ({ width = 24, height = 24, fill = 'currentColor', className }) => (
    <svg width={"100%"} height="auto" viewBox="0 0 602 428" fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* @ts-ignore */}
    <mask id="mask0_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="453" y="0" width="115" height="219">
    <path d="M567.618 0H453.16V218.101H567.618V0Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_307_9791)">
    <path d="M561.389 0H459.389C455.949 0 453.16 2.78992 453.16 6.23146V211.87C453.16 215.311 455.949 218.101 459.389 218.101H561.389C564.83 218.101 567.618 215.311 567.618 211.87V6.23146C567.618 2.78992 564.83 0 561.389 0Z" fill="#C7D1D9"/>
    <path d="M510 204.859C516.451 204.859 521.68 199.628 521.68 193.175C521.68 186.722 516.451 181.491 510 181.491C503.55 181.491 498.321 186.722 498.321 193.175C498.321 199.628 503.55 204.859 510 204.859Z" fill="white"/>
    <path d="M499.878 14.7997C500.738 14.7997 501.435 14.1022 501.435 13.2419C501.435 12.3815 500.738 11.684 499.878 11.684C499.018 11.684 498.321 12.3815 498.321 13.2419C498.321 14.1022 499.018 14.7997 499.878 14.7997Z" fill="white"/>
    <path d="M520.122 11.684H506.886C506.026 11.684 505.328 12.3815 505.328 13.2419C505.328 14.1022 506.026 14.7997 506.886 14.7997H520.122C520.982 14.7997 521.68 14.1022 521.68 13.2419C521.68 12.3815 520.982 11.684 520.122 11.684Z" fill="white"/>
      {/* @ts-ignore */}
    <mask id="mask1_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="464" y="21" width="93" height="149">
    <path d="M556.718 21.8101H464.061V169.028H556.718V21.8101Z" fill="white"/>
    </mask>
    <g mask="url(#mask1_307_9791)">
          {/* @ts-ignore */}
    <mask id="mask2_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="464" y="21" width="93" height="41">
    <path d="M556.718 21.8101H464.061V61.5357H556.718V21.8101Z" fill="white"/>
    </mask>
    <g mask="url(#mask2_307_9791)">
    <path d="M553.603 21.8101H467.176C465.456 21.8101 464.061 23.2051 464.061 24.9259V58.2367C464.061 59.9575 465.456 61.3524 467.176 61.3524H553.603C555.323 61.3524 556.718 59.9575 556.718 58.2367V24.9259C556.718 23.2051 555.323 21.8101 553.603 21.8101Z" fill="white"/>
     {/* @ts-ignore */}
    <mask id="mask3_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="471" y="33" width="48" height="17">
    <path d="M518.565 33.6717H471.069V49.5619H518.565V33.6717Z" fill="white"/>
    </mask>
    <g mask="url(#mask3_307_9791)">
    <path d="M516.229 33.6717H473.405C472.115 33.6717 471.069 34.7179 471.069 36.0085V37.2657C471.069 38.5563 472.115 39.6025 473.405 39.6025H516.229C517.519 39.6025 518.565 38.5563 518.565 37.2657V36.0085C518.565 34.7179 517.519 33.6717 516.229 33.6717Z" fill="#C7D1D9"/>
    <path d="M500.614 43.5564H473.405C472.115 43.5564 471.069 44.6026 471.069 45.8932V47.1504C471.069 48.441 472.115 49.4872 473.405 49.4872H500.614C501.904 49.4872 502.95 48.441 502.95 47.1504V45.8932C502.95 44.6026 501.904 43.5564 500.614 43.5564Z" fill="#C7D1D9"/>
    </g>
    <path d="M546.815 47.032C549.825 47.032 552.266 44.5908 552.266 41.5794C552.266 38.5681 549.825 36.1269 546.815 36.1269C543.805 36.1269 541.365 38.5681 541.365 41.5794C541.365 44.5908 543.805 47.032 546.815 47.032Z" fill="#C7D1D9"/>
    <path d="M546.815 43.9162C548.105 43.9162 549.151 42.87 549.151 41.5794C549.151 40.2889 548.105 39.2426 546.815 39.2426C545.525 39.2426 544.479 40.2889 544.479 41.5794C544.479 42.87 545.525 43.9162 546.815 43.9162Z" fill="#F2F2F2"/>
    </g>
      {/* @ts-ignore */}
    <mask id="mask4_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="464" y="67" width="93" height="31">
    <path d="M556.718 67.7672H464.061V97.3666H556.718V67.7672Z" fill="white"/>
    </mask>
    <g mask="url(#mask4_307_9791)">
    <path d="M553.603 67.7672H467.176C465.456 67.7672 464.061 69.1621 464.061 70.8829V94.0218C464.061 95.7426 465.456 97.1375 467.176 97.1375H553.603C555.323 97.1375 556.718 95.7426 556.718 94.0218V70.8829C556.718 69.1621 555.323 67.7672 553.603 67.7672Z" fill="white"/>
     {/* @ts-ignore */}
    <mask id="mask5_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="494" y="76" width="48" height="13">
    <path d="M541.924 76.58H494.428V88.7233H541.924V76.58Z" fill="white"/>
    </mask>
    <g mask="url(#mask5_307_9791)">
    <path d="M539.722 76.58H496.63C495.414 76.58 494.428 77.5664 494.428 78.7832C494.428 80 495.414 80.9864 496.63 80.9864H539.722C540.938 80.9864 541.924 80 541.924 78.7832C541.924 77.5664 540.938 76.58 539.722 76.58Z" fill="#C7D1D9"/>
    <path d="M524.106 83.9238H496.63C495.414 83.9238 494.428 84.9102 494.428 86.127C494.428 87.3438 495.414 88.3302 496.63 88.3302H524.106C525.323 88.3302 526.309 87.3438 526.309 86.127C526.309 84.9102 525.323 83.9238 524.106 83.9238Z" fill="#C7D1D9"/>
    </g>
    <path d="M480.412 91.8027C485.573 91.8027 489.756 87.6178 489.756 82.4555C489.756 77.2932 485.573 73.1083 480.412 73.1083C475.252 73.1083 471.069 77.2932 471.069 82.4555C471.069 87.6178 475.252 91.8027 480.412 91.8027Z" fill="#C5CFD6"/>
    </g>
      {/* @ts-ignore */}
    <mask id="mask6_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="464" y="103" width="93" height="31">
    <path d="M556.718 103.598H464.061V133.198H556.718V103.598Z" fill="white"/>
    </mask>
    <g mask="url(#mask6_307_9791)">
    <path d="M553.603 103.598H467.176C465.456 103.598 464.061 104.993 464.061 106.714V129.853C464.061 131.573 465.456 132.968 467.176 132.968H553.603C555.323 132.968 556.718 131.573 556.718 129.853V106.714C556.718 104.993 555.323 103.598 553.603 103.598Z" fill="white"/>
      {/* @ts-ignore */}
    <mask id="mask7_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="471" y="112" width="48" height="13">
    <path d="M518.565 112.411H471.069V124.554H518.565V112.411Z" fill="white"/>
    </mask>
    <g mask="url(#mask7_307_9791)">
    <path d="M516.363 112.411H473.271C472.055 112.411 471.069 113.397 471.069 114.614C471.069 115.831 472.055 116.817 473.271 116.817H516.363C517.579 116.817 518.565 115.831 518.565 114.614C518.565 113.397 517.579 112.411 516.363 112.411Z" fill="#C7D1D9"/>
    <path d="M500.747 119.755H473.271C472.055 119.755 471.069 120.741 471.069 121.958C471.069 123.175 472.055 124.161 473.271 124.161H500.747C501.964 124.161 502.95 123.175 502.95 121.958C502.95 120.741 501.964 119.755 500.747 119.755Z" fill="#C7D1D9"/>
    </g>
    <path d="M546.815 123.739C549.825 123.739 552.266 121.298 552.266 118.286C552.266 115.275 549.825 112.834 546.815 112.834C543.805 112.834 541.365 115.275 541.365 118.286C541.365 121.298 543.805 123.739 546.815 123.739Z" fill="#C7D1D9"/>
    <path d="M546.815 120.623C548.105 120.623 549.151 119.577 549.151 118.286C549.151 116.996 548.105 115.95 546.815 115.95C545.525 115.95 544.479 116.996 544.479 118.286C544.479 119.577 545.525 120.623 546.815 120.623Z" fill="#F2F2F2"/>
    </g>
      {/* @ts-ignore */}
    <mask id="mask8_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="464" y="139" width="93" height="31">
    <path d="M556.718 139.429H464.061V169.028H556.718V139.429Z" fill="white"/>
    </mask>
    <g mask="url(#mask8_307_9791)">
    <path d="M553.603 139.429H467.176C465.456 139.429 464.061 140.824 464.061 142.545V165.684C464.061 167.404 465.456 168.799 467.176 168.799H553.603C555.323 168.799 556.718 167.404 556.718 165.684V142.545C556.718 140.824 555.323 139.429 553.603 139.429Z" fill="white"/>
     {/* @ts-ignore */}
    <mask id="mask9_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="494" y="148" width="48" height="13">
    <path d="M541.924 148.242H494.428V160.385H541.924V148.242Z" fill="white"/>
    </mask>
    <g mask="url(#mask9_307_9791)">
    <path d="M539.722 148.242H496.63C495.414 148.242 494.428 149.228 494.428 150.445C494.428 151.662 495.414 152.648 496.63 152.648H539.722C540.938 152.648 541.924 151.662 541.924 150.445C541.924 149.228 540.938 148.242 539.722 148.242Z" fill="#C7D1D9"/>
    <path d="M524.106 155.586H496.63C495.414 155.586 494.428 156.572 494.428 157.789C494.428 159.006 495.414 159.992 496.63 159.992H524.106C525.323 159.992 526.309 159.006 526.309 157.789C526.309 156.572 525.323 155.586 524.106 155.586Z" fill="#C7D1D9"/>
    </g>
    <path d="M480.412 163.465C485.573 163.465 489.756 159.28 489.756 154.117C489.756 148.955 485.573 144.77 480.412 144.77C475.252 144.77 471.069 148.955 471.069 154.117C471.069 159.28 475.252 163.465 480.412 163.465Z" fill="#C5CFD6"/>
    </g>
    </g>
    </g>
      {/* @ts-ignore */}
    <mask id="mask10_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="60" y="17" width="125" height="237">
    <path d="M184.534 17.9155H60.7329V253.153H184.534V17.9155Z" fill="white"/>
    </mask>
    <g mask="url(#mask10_307_9791)">
    <path d="M178.305 17.9155H66.9619C63.5217 17.9155 60.7329 20.7054 60.7329 24.1469V246.922C60.7329 250.363 63.5217 253.153 66.9619 253.153H178.305C181.746 253.153 184.534 250.363 184.534 246.922V24.1469C184.534 20.7054 181.746 17.9155 178.305 17.9155Z" fill="#F2F2F2"/>
    <path d="M122.244 238.353C128.695 238.353 133.924 233.122 133.924 226.669C133.924 220.217 128.695 214.986 122.244 214.986C115.794 214.986 110.565 220.217 110.565 226.669C110.565 233.122 115.794 238.353 122.244 238.353Z" fill="white"/>
    <path d="M112.122 33.4941C112.982 33.4941 113.679 32.7966 113.679 31.9363C113.679 31.0759 112.982 30.3784 112.122 30.3784C111.262 30.3784 110.565 31.0759 110.565 31.9363C110.565 32.7966 111.262 33.4941 112.122 33.4941Z" fill="white"/>
    <path d="M132.366 30.3784H119.13C118.27 30.3784 117.573 31.0759 117.573 31.9363C117.573 32.7966 118.27 33.4941 119.13 33.4941H132.366C133.226 33.4941 133.924 32.7966 133.924 31.9363C133.924 31.0759 133.226 30.3784 132.366 30.3784Z" fill="white"/>
     {/* @ts-ignore */}
    <mask id="mask11_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="71" y="39" width="103" height="160">
    <path d="M173.634 39.7256H71.6338V198.628H173.634V39.7256Z" fill="white"/>
    </mask>
    <g mask="url(#mask11_307_9791)">
          {/* @ts-ignore */}
    <mask id="mask12_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="71" y="39" width="103" height="43">
    <path d="M173.634 39.7256H71.6338V81.788H173.634V39.7256Z" fill="white"/>
    </mask>
    <g mask="url(#mask12_307_9791)">
    <path d="M170.519 39.7256H74.7483C73.0282 39.7256 71.6338 41.1205 71.6338 42.8413V78.6722C71.6338 80.393 73.0282 81.788 74.7483 81.788H170.519C172.239 81.788 173.634 80.393 173.634 78.6722V42.8413C173.634 41.1205 172.239 39.7256 170.519 39.7256Z" fill="white"/>
     {/* @ts-ignore */}
    <mask id="mask13_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="102" y="52" width="57" height="18">
    <path d="M158.84 52.3443H102V69.1692H158.84V52.3443Z" fill="white"/>
    </mask>
    <g mask="url(#mask13_307_9791)">
    <path d="M156.504 52.3443H104.336C103.046 52.3443 102 53.3905 102 54.6811V56.3169C102 57.6074 103.046 58.6537 104.336 58.6537H156.504C157.794 58.6537 158.84 57.6074 158.84 56.3169V54.6811C158.84 53.3905 157.794 52.3443 156.504 52.3443Z" fill="#C7D1D9"/>
    <path d="M137.817 62.8599H104.336C103.046 62.8599 102 63.9061 102 65.1967V66.8325C102 68.123 103.046 69.1693 104.336 69.1693H137.817C139.107 69.1693 140.153 68.123 140.153 66.8325V65.1967C140.153 63.9061 139.107 62.8599 137.817 62.8599Z" fill="#C7D1D9"/>
    </g>
    <path d="M87.9849 70.104C93.1451 70.104 97.3284 65.9191 97.3284 60.7568C97.3284 55.5945 93.1451 51.4096 87.9849 51.4096C82.8246 51.4096 78.6414 55.5945 78.6414 60.7568C78.6414 65.9191 82.8246 70.104 87.9849 70.104Z" fill="#C5CFD6"/>
    </g>
      {/* @ts-ignore */}
    <mask id="mask14_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="71" y="88" width="103" height="33">
    <path d="M173.634 88.0194H71.6338V120.735H173.634V88.0194Z" fill="white"/>
    </mask>
    <g mask="url(#mask14_307_9791)">
    <path d="M170.519 88.0194H74.7483C73.0282 88.0194 71.6338 89.4144 71.6338 91.1352V117.619C71.6338 119.34 73.0282 120.735 74.7483 120.735H170.519C172.239 120.735 173.634 119.34 173.634 117.619V91.1352C173.634 89.4144 172.239 88.0194 170.519 88.0194Z" fill="white"/>
      {/* @ts-ignore */}
    <mask id="mask15_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="102" y="97" width="57" height="14">
    <path d="M158.84 97.834H102V110.92H158.84V97.834Z" fill="white"/>
    </mask>
    <g mask="url(#mask15_307_9791)">
    <path d="M156.504 97.834H104.336C103.046 97.834 102 98.8802 102 100.171V100.404C102 101.695 103.046 102.741 104.336 102.741H156.504C157.794 102.741 158.84 101.695 158.84 100.404V100.171C158.84 98.8802 157.794 97.834 156.504 97.834Z" fill="#C7D1D9"/>
    <path d="M137.817 106.013H104.336C103.046 106.013 102 107.059 102 108.35V108.583C102 109.874 103.046 110.92 104.336 110.92H137.817C139.107 110.92 140.153 109.874 140.153 108.583V108.35C140.153 107.059 139.107 106.013 137.817 106.013Z" fill="#C7D1D9"/>
    </g>
    <path d="M87.9849 113.724C93.1451 113.724 97.3284 109.539 97.3284 104.377C97.3284 99.2147 93.1451 95.0298 87.9849 95.0298C82.8246 95.0298 78.6414 99.2147 78.6414 104.377C78.6414 109.539 82.8246 113.724 87.9849 113.724Z" fill="#C5CFD6"/>
    </g>
      {/* @ts-ignore */}
    <mask id="mask16_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="71" y="126" width="103" height="34">
    <path d="M173.634 126.966H71.6338V159.681H173.634V126.966Z" fill="white"/>
    </mask>
    <g mask="url(#mask16_307_9791)">
    <path d="M170.519 126.966H74.7483C73.0282 126.966 71.6338 128.361 71.6338 130.082V156.566C71.6338 158.286 73.0282 159.681 74.7483 159.681H170.519C172.239 159.681 173.634 158.286 173.634 156.566V130.082C173.634 128.361 172.239 126.966 170.519 126.966Z" fill="white"/>
      {/* @ts-ignore */}
    <mask id="mask17_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="102" y="136" width="57" height="14">
    <path d="M158.84 136.781H102V149.867H158.84V136.781Z" fill="white"/>
    </mask>
    <g mask="url(#mask17_307_9791)">
    <path d="M156.504 136.781H104.336C103.046 136.781 102 137.827 102 139.117V139.351C102 140.642 103.046 141.688 104.336 141.688H156.504C157.794 141.688 158.84 140.642 158.84 139.351V139.117C158.84 137.827 157.794 136.781 156.504 136.781Z" fill="#C7D1D9"/>
    <path d="M137.817 144.959H104.336C103.046 144.959 102 146.006 102 147.296V147.53C102 148.82 103.046 149.867 104.336 149.867H137.817C139.107 149.867 140.153 148.82 140.153 147.53V147.296C140.153 146.006 139.107 144.959 137.817 144.959Z" fill="#C7D1D9"/>
    </g>
    <path d="M87.9849 152.671C93.1451 152.671 97.3284 148.486 97.3284 143.324C97.3284 138.161 93.1451 133.976 87.9849 133.976C82.8246 133.976 78.6414 138.161 78.6414 143.324C78.6414 148.486 82.8246 152.671 87.9849 152.671Z" fill="#AFB9C5"/>
    </g>
      {/* @ts-ignore */}
    <mask id="mask18_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="71" y="165" width="103" height="34">
    <path d="M173.634 165.913H71.6338V198.628H173.634V165.913Z" fill="white"/>
    </mask>
    <g mask="url(#mask18_307_9791)">
    <path d="M170.519 165.913H74.7483C73.0282 165.913 71.6338 167.308 71.6338 169.028V195.512C71.6338 197.233 73.0282 198.628 74.7483 198.628H170.519C172.239 198.628 173.634 197.233 173.634 195.512V169.028C173.634 167.308 172.239 165.913 170.519 165.913Z" fill="white"/>
     {/* @ts-ignore */}
    <mask id="mask19_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="102" y="175" width="57" height="14">
    <path d="M158.84 175.727H102V188.813H158.84V175.727Z" fill="white"/>
    </mask>
    <g mask="url(#mask19_307_9791)">
    <path d="M156.504 175.727H104.336C103.046 175.727 102 176.774 102 178.064V178.298C102 179.588 103.046 180.635 104.336 180.635H156.504C157.794 180.635 158.84 179.588 158.84 178.298V178.064C158.84 176.774 157.794 175.727 156.504 175.727Z" fill="#C7D1D9"/>
    <path d="M137.817 183.906H104.336C103.046 183.906 102 184.952 102 186.243V186.477C102 187.767 103.046 188.813 104.336 188.813H137.817C139.107 188.813 140.153 187.767 140.153 186.477V186.243C140.153 184.952 139.107 183.906 137.817 183.906Z" fill="#C7D1D9"/>
    </g>
    <path d="M87.9849 191.618C93.1451 191.618 97.3284 187.433 97.3284 182.27C97.3284 177.108 93.1451 172.923 87.9849 172.923C82.8246 172.923 78.6414 177.108 78.6414 182.27C78.6414 187.433 82.8246 191.618 87.9849 191.618Z" fill="#C5CFD6"/>
    </g>
    </g>
    </g>
      {/* @ts-ignore */}
    <mask id="mask20_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="189" y="77" width="262" height="271">
    <path d="M450.046 77.8933H189.206V347.404H450.046V77.8933Z" fill="white"/>
    </mask>
    <g mask="url(#mask20_307_9791)">
    <path d="M443.817 77.8933H195.435C191.995 77.8933 189.206 80.6832 189.206 84.1248V341.173C189.206 344.614 191.995 347.404 195.435 347.404H443.817C447.257 347.404 450.046 344.614 450.046 341.173V84.1248C450.046 80.6832 447.257 77.8933 443.817 77.8933Z" fill="#F2F2F2"/>
    <path d="M431.359 111.387H389.313C385.873 111.387 383.084 114.177 383.084 117.619V168.25C383.084 171.691 385.873 174.481 389.313 174.481H431.359C434.799 174.481 437.588 171.691 437.588 168.25V117.619C437.588 114.177 434.799 111.387 431.359 111.387Z" fill="white"/>
    <path d="M410.336 147.997C417.216 147.997 422.794 142.417 422.794 135.534C422.794 128.651 417.216 123.071 410.336 123.071C403.455 123.071 397.878 128.651 397.878 135.534C397.878 142.417 403.455 147.997 410.336 147.997Z" fill="#C7D1D9"/>
    <path d="M427.466 151.113H393.206C391.916 151.113 390.87 152.159 390.87 153.45C390.87 154.74 391.916 155.787 393.206 155.787H427.466C428.756 155.787 429.801 154.74 429.801 153.45C429.801 152.159 428.756 151.113 427.466 151.113Z" fill="#C7D1D9"/>
    <path d="M421.237 158.902H399.435C398.145 158.902 397.099 159.949 397.099 161.239C397.099 162.53 398.145 163.576 399.435 163.576H421.237C422.527 163.576 423.572 162.53 423.572 161.239C423.572 159.949 422.527 158.902 421.237 158.902Z" fill="#C7D1D9"/>
    <path d="M189.206 84.1248C189.206 80.6834 191.995 77.8933 195.435 77.8933H443.817C447.257 77.8933 450.046 80.6834 450.046 84.1248V98.9245H189.206V84.1248Z" fill="#C5CFD6"/>
    <path d="M199.328 91.9141C201.048 91.9141 202.443 90.5191 202.443 88.7984C202.443 87.0776 201.048 85.6826 199.328 85.6826C197.608 85.6826 196.214 87.0776 196.214 88.7984C196.214 90.5191 197.608 91.9141 199.328 91.9141Z" fill="#F2F2F2"/>
    <path d="M208.672 91.9141C210.392 91.9141 211.786 90.5191 211.786 88.7984C211.786 87.0776 210.392 85.6826 208.672 85.6826C206.952 85.6826 205.557 87.0776 205.557 88.7984C205.557 90.5191 206.952 91.9141 208.672 91.9141Z" fill="#F2F2F2"/>
    <path d="M218.015 91.9141C219.735 91.9141 221.13 90.5191 221.13 88.7984C221.13 87.0776 219.735 85.6826 218.015 85.6826C216.295 85.6826 214.901 87.0776 214.901 88.7984C214.901 90.5191 216.295 91.9141 218.015 91.9141Z" fill="#F2F2F2"/>
      {/* @ts-ignore */}
    <mask id="mask21_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="383" y="180" width="55" height="152">
    <path d="M437.588 180.712H383.084V331.825H437.588V180.712Z" fill="white"/>
    </mask>
    <g mask="url(#mask21_307_9791)">
    <path d="M431.359 180.712H389.313C385.873 180.712 383.084 183.502 383.084 186.944V325.594C383.084 329.036 385.873 331.825 389.313 331.825H431.359C434.799 331.825 437.588 329.036 437.588 325.594V186.944C437.588 183.502 434.799 180.712 431.359 180.712Z" fill="white"/>
     {/* @ts-ignore */}
    <mask id="mask22_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="397" y="194" width="27" height="124">
    <path d="M423.573 194.733H397.099V317.805H423.573V194.733Z" fill="white"/>
    </mask>
    <g mask="url(#mask22_307_9791)">
    <path d="M420.458 194.733H400.214C398.494 194.733 397.099 196.128 397.099 197.849V211.87C397.099 213.591 398.494 214.986 400.214 214.986H420.458C422.178 214.986 423.573 213.591 423.573 211.87V197.849C423.573 196.128 422.178 194.733 420.458 194.733Z" fill="#C7D1D9"/>
    <path d="M420.458 221.217H400.214C398.494 221.217 397.099 222.612 397.099 224.333V237.575C397.099 239.295 398.494 240.69 400.214 240.69H420.458C422.178 240.69 423.573 239.295 423.573 237.575V224.333C423.573 222.612 422.178 221.217 420.458 221.217Z" fill="#AFB9C5"/>
    <path d="M420.458 246.922H400.214C398.494 246.922 397.099 248.317 397.099 250.037V263.279C397.099 265 398.494 266.395 400.214 266.395H420.458C422.178 266.395 423.573 265 423.573 263.279V250.037C423.573 248.317 422.178 246.922 420.458 246.922Z" fill="#C7D1D9"/>
    <path d="M420.458 298.331H400.214C398.494 298.331 397.099 299.726 397.099 301.447V314.689C397.099 316.41 398.494 317.805 400.214 317.805H420.458C422.178 317.805 423.573 316.41 423.573 314.689V301.447C423.573 299.726 422.178 298.331 420.458 298.331Z" fill="#C7D1D9"/>
    <path d="M420.458 272.627H400.214C398.494 272.627 397.099 274.022 397.099 275.742V288.984C397.099 290.705 398.494 292.1 400.214 292.1H420.458C422.178 292.1 423.573 290.705 423.573 288.984V275.742C423.573 274.022 422.178 272.627 420.458 272.627Z" fill="#C5CFD6"/>
    </g>
    </g>
      {/* @ts-ignore */}
    <mask id="mask23_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="201" y="112" width="176" height="220">
    <path d="M376.855 112.166H201.664V331.825H376.855V112.166Z" fill="white"/>
    </mask>
    <g mask="url(#mask23_307_9791)">
          {/* @ts-ignore */}
    <mask id="mask24_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="201" y="112" width="176" height="37">
    <path d="M376.855 112.166H201.664V148.776H376.855V112.166Z" fill="white"/>
    </mask>
    <g mask="url(#mask24_307_9791)">
    <path d="M373.74 112.166H204.779C203.058 112.166 201.664 113.561 201.664 115.282V145.66C201.664 147.381 203.058 148.776 204.779 148.776H373.74C375.46 148.776 376.855 147.381 376.855 145.66V115.282C376.855 113.561 375.46 112.166 373.74 112.166Z" fill="white"/>
      {/* @ts-ignore */}
    <mask id="mask25_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="208" y="123" width="131" height="15">
    <path d="M338.702 123.149H208.672V137.793H338.702V123.149Z" fill="white"/>
    </mask>
    <g mask="url(#mask25_307_9791)">
    <path d="M336.366 123.149H211.008C209.717 123.149 208.672 124.196 208.672 125.486V126.304C208.672 127.595 209.717 128.641 211.008 128.641H336.366C337.656 128.641 338.702 127.595 338.702 126.304V125.486C338.702 124.196 337.656 123.149 336.366 123.149Z" fill="#C7D1D9"/>
    <path d="M293.617 132.302H211.008C209.717 132.302 208.672 133.348 208.672 134.639V135.456C208.672 136.747 209.717 137.793 211.008 137.793H293.617C294.907 137.793 295.952 136.747 295.952 135.456V134.639C295.952 133.348 294.907 132.302 293.617 132.302Z" fill="#C7D1D9"/>
    </g>
    <path d="M358.132 135.924C361.142 135.924 363.582 133.483 363.582 130.471C363.582 127.46 361.142 125.019 358.132 125.019C355.122 125.019 352.682 127.46 352.682 130.471C352.682 133.483 355.122 135.924 358.132 135.924Z" fill="#C7D1D9"/>
    <path d="M358.132 132.808C359.422 132.808 360.468 131.762 360.468 130.471C360.468 129.181 359.422 128.134 358.132 128.134C356.842 128.134 355.796 129.181 355.796 130.471C355.796 131.762 356.842 132.808 358.132 132.808Z" fill="#F2F2F2"/>
    </g>
      {/* @ts-ignore */}
    <mask id="mask26_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="201" y="155" width="176" height="31">
    <path d="M376.855 155.008H201.664V185.386H376.855V155.008Z" fill="white"/>
    </mask>
    <g mask="url(#mask26_307_9791)">
    <path d="M373.74 155.008H204.779C203.058 155.008 201.664 156.403 201.664 158.123V182.27C201.664 183.991 203.058 185.386 204.779 185.386H373.74C375.46 185.386 376.855 183.991 376.855 182.27V158.123C376.855 156.403 375.46 155.008 373.74 155.008Z" fill="white"/>
     {/* @ts-ignore */}
    <mask id="mask27_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="208" y="164" width="131" height="13">
    <path d="M338.702 164.121H208.672V176.273H338.702V164.121Z" fill="white"/>
    </mask>
    <g mask="url(#mask27_307_9791)">
    <path d="M336.425 164.121H210.949C209.691 164.121 208.672 165.141 208.672 166.4C208.672 167.658 209.691 168.678 210.949 168.678H336.425C337.683 168.678 338.702 167.658 338.702 166.4C338.702 165.141 337.683 164.121 336.425 164.121Z" fill="#C7D1D9"/>
    <path d="M293.675 171.716H210.949C209.691 171.716 208.672 172.736 208.672 173.994C208.672 175.252 209.691 176.273 210.949 176.273H293.675C294.933 176.273 295.952 175.252 295.952 173.994C295.952 172.736 294.933 171.716 293.675 171.716Z" fill="#C7D1D9"/>
    </g>
    <path d="M358.132 175.649C361.142 175.649 363.582 173.208 363.582 170.197C363.582 167.186 361.142 164.744 358.132 164.744C355.122 164.744 352.682 167.186 352.682 170.197C352.682 173.208 355.122 175.649 358.132 175.649Z" fill="#C7D1D9"/>
    <path d="M358.132 172.534C359.422 172.534 360.468 171.487 360.468 170.197C360.468 168.906 359.422 167.86 358.132 167.86C356.842 167.86 355.796 168.906 355.796 170.197C355.796 171.487 356.842 172.534 358.132 172.534Z" fill="#F2F2F2"/>
    </g>
      {/* @ts-ignore */}
    <mask id="mask28_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="201" y="191" width="176" height="31">
    <path d="M376.855 191.618H201.664V221.996H376.855V191.618Z" fill="white"/>
    </mask>
    <g mask="url(#mask28_307_9791)">
    <path d="M373.74 191.618H204.779C203.058 191.618 201.664 193.012 201.664 194.733V218.88C201.664 220.601 203.058 221.996 204.779 221.996H373.74C375.46 221.996 376.855 220.601 376.855 218.88V194.733C376.855 193.012 375.46 191.618 373.74 191.618Z" fill="white"/>
      {/* @ts-ignore */}
    <mask id="mask29_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="208" y="200" width="131" height="13">
    <path d="M338.702 200.731H208.672V212.882H338.702V200.731Z" fill="white"/>
    </mask>
    <g mask="url(#mask29_307_9791)">
    <path d="M336.425 200.731H210.949C209.691 200.731 208.672 201.751 208.672 203.009C208.672 204.268 209.691 205.288 210.949 205.288H336.425C337.683 205.288 338.702 204.268 338.702 203.009C338.702 201.751 337.683 200.731 336.425 200.731Z" fill="#C7D1D9"/>
    <path d="M293.675 208.326H210.949C209.691 208.326 208.672 209.346 208.672 210.604C208.672 211.862 209.691 212.882 210.949 212.882H293.675C294.933 212.882 295.952 211.862 295.952 210.604C295.952 209.346 294.933 208.326 293.675 208.326Z" fill="#C7D1D9"/>
    </g>
    <path d="M358.132 212.259C361.142 212.259 363.582 209.818 363.582 206.807C363.582 203.795 361.142 201.354 358.132 201.354C355.122 201.354 352.682 203.795 352.682 206.807C352.682 209.818 355.122 212.259 358.132 212.259Z" fill="#C7D1D9"/>
    <path d="M358.132 209.144C359.422 209.144 360.468 208.097 360.468 206.807C360.468 205.516 359.422 204.47 358.132 204.47C356.842 204.47 355.796 205.516 355.796 206.807C355.796 208.097 356.842 209.144 358.132 209.144Z" fill="#AFB9C5"/>
    </g>
      {/* @ts-ignore */}
    <mask id="mask30_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="201" y="301" width="176" height="31">
    <path d="M376.855 301.447H201.664V331.825H376.855V301.447Z" fill="white"/>
    </mask>
    <g mask="url(#mask30_307_9791)">
    <path d="M373.74 301.447H204.779C203.058 301.447 201.664 302.842 201.664 304.563V328.71C201.664 330.43 203.058 331.825 204.779 331.825H373.74C375.46 331.825 376.855 330.43 376.855 328.71V304.563C376.855 302.842 375.46 301.447 373.74 301.447Z" fill="white"/>
     {/* @ts-ignore */}
    <mask id="mask31_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="208" y="310" width="131" height="13">
    <path d="M338.702 310.561H208.672V322.712H338.702V310.561Z" fill="white"/>
    </mask>
    <g mask="url(#mask31_307_9791)">
    <path d="M336.425 310.561H210.949C209.691 310.561 208.672 311.581 208.672 312.839C208.672 314.097 209.691 315.117 210.949 315.117H336.425C337.683 315.117 338.702 314.097 338.702 312.839C338.702 311.581 337.683 310.561 336.425 310.561Z" fill="#C7D1D9"/>
    <path d="M293.675 318.155H210.949C209.691 318.155 208.672 319.175 208.672 320.434C208.672 321.692 209.691 322.712 210.949 322.712H293.675C294.933 322.712 295.952 321.692 295.952 320.434C295.952 319.175 294.933 318.155 293.675 318.155Z" fill="#C7D1D9"/>
    </g>
    <path d="M358.132 322.089C361.142 322.089 363.582 319.648 363.582 316.636C363.582 313.625 361.142 311.184 358.132 311.184C355.122 311.184 352.682 313.625 352.682 316.636C352.682 319.648 355.122 322.089 358.132 322.089Z" fill="#C7D1D9"/>
    <path d="M358.132 318.973C359.422 318.973 360.468 317.927 360.468 316.636C360.468 315.346 359.422 314.299 358.132 314.299C356.842 314.299 355.796 315.346 355.796 316.636C355.796 317.927 356.842 318.973 358.132 318.973Z" fill="#F2F2F2"/>
    </g>
      {/* @ts-ignore */}
    <mask id="mask32_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="201" y="264" width="176" height="32">
    <path d="M376.855 264.837H201.664V295.216H376.855V264.837Z" fill="white"/>
    </mask>
    <g mask="url(#mask32_307_9791)">
    <path d="M373.74 264.837H204.779C203.058 264.837 201.664 266.232 201.664 267.953V292.1C201.664 293.821 203.058 295.216 204.779 295.216H373.74C375.46 295.216 376.855 293.821 376.855 292.1V267.953C376.855 266.232 375.46 264.837 373.74 264.837Z" fill="white"/>
     {/* @ts-ignore */}
    <mask id="mask33_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="208" y="273" width="131" height="14">
    <path d="M338.702 273.951H208.672V286.102H338.702V273.951Z" fill="white"/>
    </mask>
    <g mask="url(#mask33_307_9791)">
    <path d="M336.425 273.951H210.949C209.691 273.951 208.672 274.971 208.672 276.229C208.672 277.487 209.691 278.507 210.949 278.507H336.425C337.683 278.507 338.702 277.487 338.702 276.229C338.702 274.971 337.683 273.951 336.425 273.951Z" fill="#C7D1D9"/>
    <path d="M293.675 281.545H210.949C209.691 281.545 208.672 282.565 208.672 283.824C208.672 285.082 209.691 286.102 210.949 286.102H293.675C294.933 286.102 295.952 285.082 295.952 283.824C295.952 282.565 294.933 281.545 293.675 281.545Z" fill="#C7D1D9"/>
    </g>
    <path d="M358.132 285.479C361.142 285.479 363.582 283.038 363.582 280.026C363.582 277.015 361.142 274.574 358.132 274.574C355.122 274.574 352.682 277.015 352.682 280.026C352.682 283.038 355.122 285.479 358.132 285.479Z" fill="#C7D1D9"/>
    <path d="M358.132 282.363C359.422 282.363 360.468 281.317 360.468 280.026C360.468 278.736 359.422 277.69 358.132 277.69C356.842 277.69 355.796 278.736 355.796 280.026C355.796 281.317 356.842 282.363 358.132 282.363Z" fill="#F2F2F2"/>
    </g>
      {/* @ts-ignore */}
    <mask id="mask34_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="201" y="228" width="176" height="31">
    <path d="M376.855 228.227H201.664V258.606H376.855V228.227Z" fill="white"/>
    </mask>
    <g mask="url(#mask34_307_9791)">
    <path d="M373.74 228.227H204.779C203.058 228.227 201.664 229.622 201.664 231.343V255.49C201.664 257.211 203.058 258.606 204.779 258.606H373.74C375.46 258.606 376.855 257.211 376.855 255.49V231.343C376.855 229.622 375.46 228.227 373.74 228.227Z" fill="white"/>
      {/* @ts-ignore */}
    <mask id="mask35_307_9791" style={{"mask-type":"luminance"}} maskUnits="userSpaceOnUse" x="208" y="237" width="131" height="13">
    <path d="M338.702 237.341H208.672V249.492H338.702V237.341Z" fill="white"/>
    </mask>
    <g mask="url(#mask35_307_9791)">
    <path d="M336.425 237.341H210.949C209.691 237.341 208.672 238.361 208.672 239.619C208.672 240.878 209.691 241.898 210.949 241.898H336.425C337.683 241.898 338.702 240.878 338.702 239.619C338.702 238.361 337.683 237.341 336.425 237.341Z" fill="#C7D1D9"/>
    <path d="M293.675 244.935H210.949C209.691 244.935 208.672 245.956 208.672 247.214C208.672 248.472 209.691 249.492 210.949 249.492H293.675C294.933 249.492 295.952 248.472 295.952 247.214C295.952 245.956 294.933 244.935 293.675 244.935Z" fill="#C7D1D9"/>
    </g>
    <path d="M358.132 248.869C361.142 248.869 363.582 246.428 363.582 243.417C363.582 240.405 361.142 237.964 358.132 237.964C355.122 237.964 352.682 240.405 352.682 243.417C352.682 246.428 355.122 248.869 358.132 248.869Z" fill="#C7D1D9"/>
    <path d="M358.132 245.753C359.422 245.753 360.468 244.707 360.468 243.417C360.468 242.126 359.422 241.08 358.132 241.08C356.842 241.08 355.796 242.126 355.796 243.417C355.796 244.707 356.842 245.753 358.132 245.753Z" fill="#F2F2F2"/>
    </g>
    </g>
    </g>
    <path d="M299.771 45.1781C299.771 43.8875 298.725 42.8413 297.435 42.8413C296.145 42.8413 295.099 43.8875 295.099 45.1781V59.9778C295.099 61.2684 296.145 62.3146 297.435 62.3146C298.725 62.3146 299.771 61.2684 299.771 59.9778V45.1781Z" fill="#C7D1D9"/>
    <path d="M310.672 32.7152C310.672 31.4246 309.626 30.3784 308.336 30.3784C307.046 30.3784 306 31.4246 306 32.7152V59.9778C306 61.2684 307.046 62.3146 308.336 62.3146C309.626 62.3146 310.672 61.2684 310.672 59.9778V32.7152Z" fill="#C7D1D9"/>
    <path d="M321.572 26.4837C321.572 25.1931 320.527 24.1469 319.237 24.1469C317.946 24.1469 316.901 25.1931 316.901 26.4837V59.9778C316.901 61.2684 317.946 62.3146 319.237 62.3146C320.527 62.3146 321.572 61.2684 321.572 59.9778V26.4837Z" fill="#C7D1D9"/>
    <path d="M332.473 32.7152C332.473 31.4246 331.427 30.3784 330.137 30.3784C328.847 30.3784 327.802 31.4246 327.802 32.7152V59.9778C327.802 61.2684 328.847 62.3146 330.137 62.3146C331.427 62.3146 332.473 61.2684 332.473 59.9778V32.7152Z" fill="#C7D1D9"/>
    <path d="M343.374 20.2523C343.374 18.9617 342.328 17.9155 341.038 17.9155C339.748 17.9155 338.702 18.9617 338.702 20.2523V59.9778C338.702 61.2684 339.748 62.3146 341.038 62.3146C342.328 62.3146 343.374 61.2684 343.374 59.9778V20.2523Z" fill="#C5CFD6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M543.299 321.839C541.803 323.428 539.683 324.426 537.326 324.426C532.797 324.426 529.125 320.764 529.125 316.247C529.125 311.73 532.797 308.068 537.326 308.068V292.879C524.44 292.879 513.893 303.397 513.893 316.247C513.893 329.097 524.44 339.615 537.326 339.615C544.094 339.615 550.212 336.759 554.382 332.216L543.299 321.839Z" fill="#F2F2F2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M537.252 292.879V308.068C541.767 308.068 545.428 311.73 545.428 316.247H560.611C560.611 303.397 550.097 292.879 537.252 292.879Z" fill="#AFB9C5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M545.114 316.247C545.114 318.901 543.864 321.259 541.924 322.783L551.471 334.941C557.028 330.589 560.611 323.841 560.611 316.247H545.114Z" fill="#C5CFD6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.6199 131.253C21.469 132.472 19.8386 133.236 18.0252 133.236C14.5408 133.236 11.7168 130.429 11.7168 126.966C11.7168 123.503 14.5408 120.696 18.0252 120.696V109.051C8.11328 109.051 0 117.114 0 126.966C0 136.818 8.11328 144.882 18.0252 144.882C23.2319 144.882 27.9371 142.692 31.145 139.209L22.6199 131.253Z" fill="#F2F2F2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9084 109.051V120.696C21.3702 120.696 24.1764 123.503 24.1764 126.966H35.8168C35.8168 117.114 27.7565 109.051 17.9084 109.051Z" fill="#AFB9C5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.1942 126.966C24.1942 128.956 23.2568 130.726 21.8015 131.868L28.9618 140.987C33.1305 137.722 35.8168 132.662 35.8168 126.966H24.1942Z" fill="#C5CFD6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M564.51 116.829C564.507 116.837 564.503 116.845 564.499 116.855C564.503 116.847 564.51 116.815 564.51 116.829Z" fill="#C5CFD6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M564.502 116.841C564.502 116.842 564.501 116.846 564.501 116.849C564.501 116.841 564.515 116.817 564.502 116.841Z" fill="#C5CFD6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M564.556 116.709C564.545 116.74 564.531 116.77 564.519 116.801C564.525 116.785 564.531 116.773 564.538 116.757C564.376 117.162 564.478 116.899 564.556 116.709Z" fill="#C5CFD6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M564.521 116.803C564.515 116.817 564.509 116.833 564.502 116.847C564.457 116.934 564.521 116.803 564.521 116.803Z" fill="#C5CFD6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M564.496 116.853C564.487 116.873 564.541 116.779 564.496 116.853V116.853Z" fill="#C5CFD6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M564.502 116.842L564.506 116.838C564.504 116.84 564.504 116.842 564.502 116.842Z" fill="#C5CFD6"/>
    <path d="M597.985 76.3354H530.244C528.524 76.3354 527.13 77.7304 527.13 79.4512V122.292C527.13 124.013 528.524 125.408 530.244 125.408H597.985C599.705 125.408 601.099 124.013 601.099 122.292V79.4512C601.099 77.7304 599.705 76.3354 597.985 76.3354Z" fill="#C5CFD6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M555.16 90.3562L533.359 125.408H576.962L555.16 90.3562Z" fill="#AFB9C5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M576.573 99.7034L557.496 125.408H595.649L576.573 99.7034Z" fill="#AFB9C5"/>
    <path d="M584.748 99.7034C588.618 99.7034 591.756 96.5648 591.756 92.693C591.756 88.8213 588.618 85.6826 584.748 85.6826C580.878 85.6826 577.74 88.8213 577.74 92.693C577.74 96.5648 580.878 99.7034 584.748 99.7034Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M60.7275 362.972C60.7308 362.98 60.734 362.988 60.7388 362.998C60.734 362.99 60.7275 362.958 60.7275 362.972Z" fill="#C5CFD6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M60.7344 362.984C60.7344 362.985 60.736 362.988 60.736 362.991C60.736 362.984 60.7219 362.96 60.7344 362.984Z" fill="#C5CFD6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M60.6807 362.852C60.6916 362.883 60.7056 362.913 60.718 362.944C60.7118 362.928 60.7056 362.916 60.6994 362.9C60.8613 363.305 60.7585 363.042 60.6807 362.852Z" fill="#C5CFD6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M60.7158 362.946C60.722 362.96 60.7283 362.976 60.7345 362.99C60.7797 363.077 60.7158 362.946 60.7158 362.946Z" fill="#C5CFD6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M60.7412 362.996C60.6962 362.922 60.7508 363.015 60.7412 362.996V362.996Z" fill="#C5CFD6"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M60.7354 362.985L60.7314 362.981C60.7334 362.983 60.7334 362.985 60.7354 362.985Z" fill="#C5CFD6"/>
    <path d="M8.56499 303.005H112.901C114.621 303.005 116.015 304.4 116.015 306.121V373.109C116.015 374.83 114.621 376.225 112.901 376.225H8.56499C6.8449 376.225 5.45049 374.83 5.45049 373.109V306.121C5.45049 304.4 6.8449 303.005 8.56499 303.005Z" fill="#C7D1D9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M74.9616 324.036L106.928 376.225H42.9951L74.9616 324.036Z" fill="#AFB9C5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M42.0022 338.016L70.2624 376.225H13.7419L42.0022 338.016Z" fill="#C5CFD6"/>
    <path d="M29.9771 338.057C24.1718 338.057 19.4656 333.349 19.4656 327.541C19.4656 321.734 24.1718 317.026 29.9771 317.026C35.7824 317.026 40.4885 321.734 40.4885 327.541C40.4885 333.349 35.7824 338.057 29.9771 338.057Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M304.053 116.84C294.808 116.84 287.313 124.338 287.313 133.587V149.945C287.313 158.564 293.822 165.663 302.191 166.589C302.194 166.629 302.196 166.669 302.198 166.709H324.778C328.347 163.221 324.85 140.981 322.166 131.087C320.959 123.024 314.007 116.84 305.611 116.84H304.053Z" fill="#4A312C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M305.171 154.419C310.213 157.459 315.167 158.928 317.493 158.329C323.324 156.825 323.86 135.484 319.348 127.07C314.836 118.657 291.641 114.99 290.472 131.66C290.066 137.445 292.494 142.608 296.082 146.821L289.649 176.818H308.336L305.171 154.419Z" fill="#B28B67"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M287.999 131.757C288.542 131.22 289.067 130.701 289.588 130.236L289.562 129.631C289.562 129.631 295.427 117.619 304.444 117.619C313.452 117.619 316.301 121.809 318.723 125.37L318.73 125.379C317.463 129.728 313.645 131.487 309.328 133.476C307.469 134.333 305.518 135.232 303.638 136.399C302.901 135.8 301.962 135.441 300.939 135.441C298.574 135.441 296.657 137.359 296.657 139.725C296.657 142.091 298.574 144.009 300.939 144.009C301.004 144.009 301.069 144.008 301.134 144.005C301.995 153.257 301.65 163.823 299.084 166.331H276.504C275.855 155.065 278.769 151.851 281.746 148.566C284.547 145.476 287.404 142.324 287.404 132.343C287.605 132.146 287.803 131.95 287.999 131.757Z" fill="#4A312C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M283.42 241.469L311.942 339.792L325.357 413.613H338.821L325.381 241.469H283.42Z" fill="#B28B67"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M283.42 241.469L311.942 339.792L325.357 413.613H338.821L325.381 241.469H283.42Z" fill="black" fill-opacity="0.1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M270.602 241.469C272.592 292.547 271.807 320.406 271.362 325.047C270.917 329.688 268.388 360.767 251.156 415.171H265.161C287.981 362.98 295.947 331.9 298.564 325.047C301.182 318.194 308.911 290.334 320.197 241.469H270.602Z" fill="#B28B67"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M282.808 241.469C290.227 275.302 303.058 329.308 321.302 403.487H340.996C342.451 327.35 336.263 277.238 325.547 241.469H282.808Z" fill="#191847"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M270.503 241.469C272.451 292.547 267.4 342.868 251.754 404.283H272.766C295.628 352.871 312.013 302.797 323.212 241.469H270.503Z" fill="#393F82"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M249.16 425.297L249.873 413.807C249.904 413.309 250.389 412.968 250.877 413.071C257.626 414.506 266.933 411.277 266.933 411.277C276.301 417.915 289.805 420.961 294.561 421.873C295.481 422.05 296.071 422.979 295.816 423.88L294.751 427.634H266.933H250.706L249.16 425.297Z" fill="#191847"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M322.351 425.297L323.064 413.807C323.095 413.309 323.579 412.968 324.068 413.071C330.817 414.506 340.124 411.277 340.124 411.277C349.492 417.915 362.996 420.961 367.751 421.873C368.672 422.05 369.262 422.979 369.007 423.88L367.942 427.634H340.124H323.897L322.351 425.297Z" fill="#191847"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M383.267 207.745L355.054 220.14L364.062 231.685L385.192 215.478C395.746 213.539 400.641 211.873 399.876 210.48C399.231 209.306 398.031 209.24 396.927 209.179C396.065 209.131 395.262 209.087 394.823 208.524C393.821 207.24 396.294 203.271 398.803 199.606C401.312 195.94 398.96 196.072 397.584 196.511C392.981 199.073 388.209 202.818 383.267 207.745ZM256.947 226.721L240.991 223.554C230.17 252.086 223.719 267.721 221.638 270.46C220.111 272.468 210.698 277.641 204.327 280.403C201.77 281.512 202.319 283.674 203.069 284.366C207.8 288.729 212.198 287.2 216.986 285.535C218.372 285.053 219.791 284.56 221.26 284.194C225.062 283.25 226.219 285.206 227.312 287.055C228.281 288.691 229.199 290.243 231.859 289.624C231.738 289.102 231.589 288.517 231.429 287.885C230.392 283.803 228.863 277.783 231.131 274.286C232.296 272.491 240.901 256.636 256.947 226.721Z" fill="#B28B67"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M300.312 163.835L307.045 162.679C321.71 176.91 327.694 211.101 337.467 215.626C346.707 219.905 359.806 215.764 375.376 210.174L380.143 220.831C365.997 236.142 335.86 249.077 323.644 241.693C304.113 229.886 299.604 187.718 300.312 163.835Z" fill="#9F132A"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M270.962 248.48C270.962 248.48 311.457 248.48 330.535 248.48C333.258 248.48 332.745 244.548 332.351 242.563C327.81 219.662 310.081 194.976 310.081 162.377L292.897 159.681C278.684 182.549 273.769 210.705 270.962 248.48Z" fill="#C7D1D9"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M291.985 159.681H291.789C253.245 183.442 229.952 220.275 221.908 270.18L234.396 272.627C242.667 262.986 252.875 247.208 265.02 225.293C261.294 238.494 258.574 252.034 257.725 264.837H311.351C321.732 238.591 298.592 159.681 298.592 159.681H294.89H291.985Z" fill="#C31532"/>
    </svg>
    
);

export default SvgIcon;
