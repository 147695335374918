import React from 'react';
import { Card, Button } from 'antd';
import '../styles/CampaignCard.scss'; // Make sure this path is correct
import Meta from 'antd/es/card/Meta';
import ShareButtonStatic from './ShareButtonStatic';

type CampaignCardProps = {
  name: string;
  amountRaised: string;
  endDate: string;
  status: string;
  onDetailsClick: () => void;
  campaignId: string;
  userId: number,
  title: string,
  blurb: string,
  link: string
};

const ActiveCampaignCard: React.FC<CampaignCardProps> = ({
  name,
  amountRaised,
  endDate,
  status,
  onDetailsClick,
  campaignId,
  userId,
  title,
  blurb,
  link
}) => {
    const handleNavigateToCampaign = () => {
        window.open(`${process.env.REACT_APP_BASE_URL_FE}raise/${link}/${userId}`, '_blank');
      };
  return (
    <Card className={`campaign-card ${status.toLowerCase()}`}>
      <Meta 
        title={name} 
        description="Campaign Name"
      />
      <Meta 
        title={amountRaised} 
        description="Raised by Me" 
      />
       <Meta 
        title={ <ShareButtonStatic 
          campaignId={campaignId ?? ''}
          userId={userId?? 0}
          showEmail={false}
            schoolName={title ?? ''}
            blurb={blurb ?? ''}
           />} 
        description="Share" 
      />
   
     <div className={`details-button ${status.toLowerCase()}`}>
     {/* <span className="details-text">{status} Campaign</span> */}
     <Button type="primary" onClick={handleNavigateToCampaign}>Open store</Button>
        <Button type="primary" onClick={onDetailsClick}>Details</Button>
      </div>
    </Card>
  );
};

export default ActiveCampaignCard;
