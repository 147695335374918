// File path: src/components/CSVUploader.tsx

import React, { ReactNode } from 'react';
import { Upload, Button, message, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
 // @ts-ignore
import Papa from 'papaparse';
import { RcFile, UploadProps } from 'antd/es/upload/interface';

interface ParsedData {
  email_address: string;
  name: string;

}

interface CSVUploaderProps {
  onDataParsed: (data: ParsedData[]) => void;
  loading: boolean;
  button?: ReactNode
}

const CSVUploader: React.FC<CSVUploaderProps> = ({ onDataParsed, loading, button }) => {

  const handleFileUpload = (file: RcFile) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
       // @ts-ignore
      complete: (result) => {
        const formattedData = result.data.map((row: any) => ({
          email_address: row.email,
          name: row.name,
        }));
        onDataParsed(formattedData);
      },
       // @ts-ignore
      error: (error) => {
        message.error(`Error parsing CSV file: ${error.message}`);
      },
    });
  };

  const customRequest: UploadProps['customRequest'] = (options) => {
    const { file, onSuccess } = options;
    setTimeout(() => {
      onSuccess && onSuccess("ok");
    }, 0);
    handleFileUpload(file as RcFile);
  };

  return (
    <Upload
      accept=".csv"
      showUploadList={false}
      customRequest={customRequest}
    >
     {button?button: <Button icon={<UploadOutlined />}>Upload CSV</Button>}
      {loading && <Spin style={{ marginLeft: 10 }} />}
    </Upload>
  );
};

export default CSVUploader;
