// src/components/ContactsModal.tsx

import React, { useEffect, useState } from 'react';
import { Modal, Table, Pagination, Spin } from 'antd';
import { useGetInviteStudentsQuery, useGetLeaderBoardQuery } from '../services/admin/inventoryApi';
import { Campaign } from '../types.ts';
import { formatPrice } from '../utils';

interface LeaderBoardModalProps {
  campaignId: string;
  visible: boolean;
  onClose: () => void;
  isMobile: boolean;
}

const LeaderBoardModal: React.FC<LeaderBoardModalProps> = ({ campaignId, visible, onClose,isMobile }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  
  const { data:studentData, error, isLoading:isLoadingStudents } = useGetLeaderBoardQuery({
    campaignId:campaignId ??'0',
    skip: (currentPage - 1) * pageSize,
    limit: pageSize,
  });


  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
     width:'20%'
    },
    {
      title: 'Donation amount',
      dataIndex: 'donation_amount',
      key: 'donation_amount',
      sorter: (a: any, b: any) => a.donation_amount - b.donation_amount, 
      render: (number: number) => (
          <p style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>${formatPrice(number)} </p>
        ),
         width:'20%'
    },
    {
      title: 'Products Sold',
      dataIndex: 'products_sold',
      key: 'cost',
      sorter: (a: any, b: any) => a.products_sold - b.products_sold, 
        width:'20%'
    },
    {
      title: 'Products Sold Amount',
      dataIndex: 'product_sold_amount',
      key: 'product_sold_amount',
      sorter: (a: any, b: any) => a.product_sold_amount - b.product_sold_amount,
      render: (number: number) => (
        <p style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>${number?formatPrice(number):0} </p>
      ),
    width:'20%'
    },
    {
      title: 'Total Raised',
      dataIndex: 'amount_total_sold',
      key: 'amount_total_sold',
      sorter: (a: any, b: any) => a.amount_total_sold - b.amount_total_sold,
      render: (number: number) => (
        <p style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>${formatPrice(number)} </p>
      ),
       width:'20%'
    },
  ];

  return (
    <Modal
      title="LeaderBoard"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width="80%"
    >
      <Spin spinning={isLoadingStudents}>
        <Table
          columns={columns}
          // @ts-ignore
          dataSource={studentData ||  []}
          pagination={false}
         
        scroll={{ x: isMobile?'100vw':undefined }} // Enable horizontal scroll
        />
      
      </Spin>
    </Modal>
  );
};

export default LeaderBoardModal;
