import React from 'react';
import '../styles/Privacy.scss';

const PrivacyPolicy= () => {
    return (
        <div className="privacy-container">
            <h1>Your privacy is critically important to us.</h1>
            <p>Fundraising Manager, Inc is located at:</p>
            <address>
                12154 Darnestown Rd<br/>
                #623<br/>
                Gaithersburg, MD 20878<br/>
                United States
            </address>

            <p>It is Fundraising Manager's policy to respect your privacy regarding any information we may collect while
                operating our website. This Privacy Policy applies to this website (hereinafter, "us", "we"). We respect
                your privacy and are committed to protecting personally identifiable information you may provide us with
                through the Website. We have adopted this privacy policy ("Privacy Policy") to explain what information
                may be collected on our Website, how we use this information, and under what circumstances we may
                disclose the information to third parties. This Privacy Policy applies only to information we collect
                through the Website and does not apply to our collection of information from other sources.</p>

            <p>This Privacy Policy, together with the Terms and conditions posted on our Website, set forth the general
                rules and policies governing your use of our Website. Depending on your activities when visiting our
                Website, you may be required to agree to additional terms and conditions.</p>

            <h2>Website Visitors</h2>
            <p>Like most website operators, Fundraising Manager collects non-personally identifying information of the
                sort that web browsers and servers typically make available, such as the browser type, language
                preference, referring site, and the date and time of each visitor request. Fundraising Manager's purpose
                in collecting non-personally identifying information is to better understand how Fundraising Manager's
                visitors use its website. From time to time, Fundraising Manager may release non-personally identifying
                information in the aggregate, e.g., by publishing a report on trends in the usage of its website.</p>

            <p>Fundraising Manager also collects potentially personally identifying information like Internet Protocol
                (IP) addresses for logged in users and for users leaving comments on this website's blog posts.
                Fundraising Manager only discloses logged in user and commenter IP addresses under the same
                circumstances that it uses and discloses personally identifying information as described below.</p>

            <h2>Gathering of Personally Identifying Information</h2>
            <p>Certain visitors to Fundraising Manager's websites choose to interact with Fundraising Manager in ways
                that require Fundraising Manager to gather personally identifying information. The amount and type of
                information that Fundraising Manager gathers depends on the nature of the interaction. For example, we
                ask visitors who sign up for a blog to provide a username and email address.</p>

            <h2>Security</h2>
            <p>The security of your Personal Information is important to us but remember that no method of transmission
                over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially
                acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>

            <h2>COPPA (Children Online Privacy Protection Act)</h2>
            <p>We do not specifically market to children under 13. Our website is intended to be used by
                Parents/Guardians over the age of 13. By completing the registration process, you agree that you are
                over the age of 13. We never ask for a child’s contact information. Any personal information of students
                (name, gender, picture, or personal message) can only be entered by a registered Parent/Guardian.</p>

            <h2>Advertisements</h2>
            <p>Ads appearing on our website may be delivered to users by advertising partners, who may set cookies.
                These cookies allow the ad server to recognize your computer each time they send you an online
                advertisement to compile information about you or others who use your computer. This information allows
                ad networks to, among other things, deliver targeted advertisements that they believe will be of most
                interest to you. This Privacy Policy covers the use of cookies by Fundraising Manager and does not cover
                the use of cookies by any advertisers.</p>

            <h2>Links To External Sites</h2>
            <p>Our Service may contain links to external sites that are not operated by us. If you click on a
                third-party link, you will be directed to that third party's site. We strongly advise you to review the
                Privacy Policy and terms and conditions of every site you visit.</p>

            <p>We have no control over, and assume no responsibility for the content, privacy policies or practices of
                any third-party sites, products or services.</p>

            <h2>Aggregated Statistics</h2>
            <p>Fundraising Manager may collect statistics about the behavior of visitors to its website. Fundraising
                Manager may display this information publicly or provide it to others. However, Fundraising Manager does
                not disclose your personally identifying information.</p>

            <h2>Cookies</h2>
            <p>To enrich and perfect your online experience, Fundraising Manager uses "Cookies", similar technologies
                and services provided by others to display personalized content, appropriate advertising and store your
                preferences on your computer.</p>

            <p>A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's
                browser provides to the website each time the visitor returns. Fundraising Manager uses cookies to help
                Fundraising Manager identify and track visitors, their usage of their website access preferences.
                Fundraising Manager visitors who do not wish to have cookies placed on their computers should set their
                browsers to refuse cookies before using Fundraising Manager's websites, with the drawback that certain
                features of Fundraising Manager's websites may not function properly without the aid of cookies.</p>

            <p>By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and
                agree to Fundraising Manager's use of cookies.</p>

            <h2>Privacy Policy Changes</h2>
            <p>Although most changes are likely to be minor, Fundraising Manager may change its Privacy Policy from time
                to time, and in Fundraising Manager's sole discretion. Fundraising Manager encourages visitors to
                frequently check this page for any changes to its Privacy Policy. Your continued use of this site after
                any change in this Privacy Policy will constitute your acceptance of such change.</p>

        </div>
    );
};

export default PrivacyPolicy;
