// src/components/ModalActionButtons.tsx
import React from 'react';

interface ButtonProps {
  onClick: () => void;
}

export const AddButton: React.FC<ButtonProps> = ({ onClick }) => (
  <button className="modal-action-button add-button" onClick={onClick}>+</button>
);

export const RemoveButton: React.FC<ButtonProps> = ({ onClick }) => (
  <button className="modal-action-button remove-button" onClick={onClick}>-</button>
);

