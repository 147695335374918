import React from 'react';
import { Modal, List } from 'antd';
import { Product } from '../types.ts';
import { formatPrice } from '../utils';
import { AddButton, RemoveButton } from './ModalActionButtons';
import '../styles/FreebieModal.scss';

interface FreebieModalProps {
  visible: boolean;
  onCancel: () => void;
  products: Product[];
  getCartItemQuantity: (product: Product) => number;
  handleAddToCart: (product: Product) => void;
  handleRemoveFromCart: (id: string) => void;
}

const ItemInfo: React.FC<{ item: Product }> = ({ item }) => (
  <div className="item-info">
    <img src={item.primary_image_url} alt={item.title} />
    <div>
      <h3>{item.title?.length > 20 ? item.title.substring(0, 20) + "..." : item.title}</h3>
      <p>${formatPrice(item.cost)}</p>
    </div>
  </div>
);

const ItemActions: React.FC<{ item: Product; handleAddToCart: (product: Product) => void; handleRemoveFromCart: (id: string) => void; getCartItemQuantity: (product: Product) => number }> = ({ item, handleAddToCart, handleRemoveFromCart, getCartItemQuantity }) => (
  <div className="item-actions">
    <RemoveButton onClick={() => handleRemoveFromCart(item.id)} />
    <span className="quantity">{getCartItemQuantity(item)}</span>
    <AddButton onClick={() => handleAddToCart(item)} />
  </div>
);

const ItemPrice: React.FC<{ item: Product }> = ({ item }) => (
  <span className="item-price">${formatPrice(item.cost)}</span>
);

const TotalPrice: React.FC<{ item: Product; getCartItemQuantity: (product: Product) => number }> = ({ item, getCartItemQuantity }) => (
  <div className="total-price">
    ${formatPrice(getCartItemQuantity(item) * item.cost)}
  </div>
);

const FreebieModal: React.FC<FreebieModalProps> = ({
  visible,
  onCancel,
  products,
  getCartItemQuantity,
  handleAddToCart,
  handleRemoveFromCart,
}) => (
  <Modal
    className="freebie-modal"
    title="Add another item for a dollar or less in shipping!"
    visible={visible}
    bodyStyle={{ maxHeight: '400px', overflowY: 'auto' }}
    onCancel={onCancel}
    footer={null}
  >
    <List
      itemLayout="vertical"
      size="large"
      dataSource={products}
      renderItem={item => (
        <li key={item.id} className="item-list">
          <ItemInfo item={item} />
          <div className="item-details">
            <ItemActions
              item={item}
              handleAddToCart={handleAddToCart}
              handleRemoveFromCart={handleRemoveFromCart}
              getCartItemQuantity={getCartItemQuantity}
            />
            <ItemPrice item={item} />
            <TotalPrice item={item} getCartItemQuantity={getCartItemQuantity} />
          </div>
        </li>
      )}
    />
  </Modal>
);

export default FreebieModal;
