// src/OrderSuccess.tsx
import React, { ReactNode } from 'react';
import { Result, Button } from 'antd';
import { FileDoneOutlined, SmileOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import ConfettiExplosion from 'react-confetti-explosion';
import { useGetCampaignForStudentQuery, useGetCampaignForUserQuery } from '../services/campaign';
import useWindowSize from 'react-use/lib/useWindowSize'
interface OrderSuccessProps {
 closed: boolean,
 display?: ReactNode
}

const OrderSuccess: React.FC<OrderSuccessProps> = ({closed, display}) => {
  const navigate = useNavigate();
  const { id, campaign_id, user_id } = useParams<{ id: string, campaign_id: string, user_id: string }>();

  const { data, error, isLoading, isError } = useGetCampaignForUserQuery(campaign_id || '0', { skip: !campaign_id });
  const { width, height } = useWindowSize()
  return (
    <>
   
      <Result
      icon={<div className="school-logo">
       {!closed&&<ConfettiExplosion  width={5000}
  particleCount={500}
      height={5000}/>  }
        <img src={data?.logo_image_url} alt="School Logo" className="logo" />
        <h1 className="main-heading">{data?.title}</h1>
      </div>}
      
      status="success"
      title={closed?"The Campaign is closed":"Order Placed Successfully!"}
      subTitle={closed?"Thank you for your support":"Thank you for your purchase. Your order has been placed and will be processed shortly."}
      extra={
        <>
        {display}
        <Button type="primary" onClick={()=>{ closed?navigate("/"):navigate(-1);}} style={{
            width:"40%",
            padding: '15px 20px',
            borderRadius:'10px',
            marginInlineEnd: '8px'
          }}>
          Go to Home
        </Button>
        </>
        
      }
    />
    </>
   
  );
};

export default OrderSuccess;
