import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DonationState {
  amount: number;
}

const initialState: DonationState = {
  amount: 0,
};

const donationSlice = createSlice({
  name: 'donation',
  initialState,
  reducers: {
    setDonationAmount: (state, action: PayloadAction<number>) => {
      state.amount = action.payload;
    },
  },
});

export const { setDonationAmount } = donationSlice.actions;
export default donationSlice.reducer;
